import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, FeatureGroup, Marker, Popup, Polygon } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import markerIcon2xPng from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png';
import * as turf from '@turf/turf';
import { createOrUpdateArea, getAreeUtente, deletArea } from '../../api/prodotti/api';
import { debounce } from 'lodash';
// Configurazione icone Marker
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2xPng,
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
});

// Funzione per aggiungere un offset ai marker
const getOffsetPosition = (lat, lng, index) => {
  const offset = 0.0001 * index; // Offset per evitare sovrapposizioni
  return [lat + offset, lng + offset];
};

const MapWithDrawing = ({ points }) => {
  const [polygons, setPolygons] = useState([]); // Perimetri disegnati
  const mapRef = useRef();


  // Funzione per verificare se un punto è all'interno di un perimetro
  const isPointInPolygon = (point, polygons) => {
    const turfPoint = turf.point([point.lng, point.lat]);

    for (const polygon of polygons) {
      const turfPolygon = turf.polygon([[...polygon.coordinates.map(coord => [coord.lng, coord.lat])]]);
      if (turf.booleanPointInPolygon(turfPoint, turfPolygon)) {
        return polygon.name;
      }
    }

    return null; // Punto non in nessun perimetro
  };

  // Recupera le aree dal backend
  const giveMeAree = async () => {
    let aree = await getAreeUtente();
    let tmpAree = aree.map((area) => ({
      name: area.nome,
      coordinates: area.area.map(([lng, lat]) => ({ lat, lng })), // Converte [lng, lat] a {lat, lng}
    }));

    console.log("Aree trasformate:", tmpAree); // Debug
    setPolygons(tmpAree);
    fackeClick();
  };

  useEffect(() => {
    giveMeAree();
  }, []);
  // Simula un click sul primo selettore

const fackeClick = () => {
	const firstSelector = document.querySelector(
		      '#root > div > div.noleggio-container-data > div > div.noleggio-data-corpo-centrale > div > div > div > div > div.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > div:nth-child(2) > div > a.leaflet-draw-edit-edit'
	);

	if (firstSelector) {
    console.log("firstSelector", firstSelector)
	  firstSelector.click(); // Simula il click sul primo selettore

	  // Aggiungi un piccolo ritardo prima di cliccare sul secondo selettore
	  setTimeout(() => {
	    const secondSelector = document.querySelector(
	 '#root > div > div.noleggio-container-data > div > div.noleggio-data-corpo-centrale > div > div > div > div > div.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > div:nth-child(2) > ul > li:nth-child(2) > a'
	    );

	    if (secondSelector) {
        console.log("secondSelector", secondSelector)
	      secondSelector.click(); // Simula il click sul secondo selettore
	    } else {
	      console.error('Second selector not found');
	    }
	  }, 270); // Ritardo di 100ms
	} else {
	  console.error('First selector not found');
	}
}

  // Gestione della creazione di un nuovo perimetro
  const handleCreated = async (e) => {
    const layer = e.layer;
    const polygonCoordinates = layer.getLatLngs()[0].map((point) => ({
      lat: point.lat,
      lng: point.lng,
    }));

    // Aggiungi il primo punto come ultimo punto per chiudere il poligono
    if (polygonCoordinates.length > 0) {
      polygonCoordinates.push(polygonCoordinates[0]);
    }

    const name = prompt("Inserisci il nome del perimetro:");
    if (name) {
      setPolygons((prev) => [...prev, { name, coordinates: polygonCoordinates }]);
    }

    console.log("Poligono creato:", { name, coordinates: polygonCoordinates });
    let updateCreateArea = await createOrUpdateArea(
      polygonCoordinates.map(({ lat, lng }) => [lng, lat]),
      name
    );
    console.log(updateCreateArea);
    giveMeAree();
  };

  // Gestione della modifica di un poligono
  /*
  const handleEdited = debounce(async (e) => {
    console.log("Modifica dei poligoni iniziata:", e);
  
    const layers = e.layers;
    let updatedPolygons = [...polygons];
  
    layers.eachLayer(async (layer) => {
      const polygonCoordinates = layer.getLatLngs()[0].map((point) => ({
        lat: point.lat,
        lng: point.lng,
      }));
  
      if (polygonCoordinates.length > 0) {
        polygonCoordinates.push(polygonCoordinates[0]); // Chiudi il poligono
      }
  
      const name = layer.options.name || prompt("Inserisci il nome per il poligono modificato:");
      if (name) {
        layer.options.name = name; // Imposta il nome sul layer
  
        updatedPolygons = updatedPolygons.map((polygon) =>
          polygon.name === name
            ? { ...polygon, coordinates: polygonCoordinates }
            : polygon
        );
  
        console.log("Poligono aggiornato:", { name, coordinates: polygonCoordinates });
  
        // Aggiorna nel backend
        await createOrUpdateArea(
          polygonCoordinates.map(({ lat, lng }) => [lng, lat]),
          name
        );
      }
    });
  
    setPolygons(updatedPolygons);
    giveMeAree(); // Sincronizza i dati dal backend
  }, 500); // 500ms di debounce
  
  
 */
  

  // Gestione dell'eliminazione di un poligono
  const handleDeleted = async (e) => {
    console.log(e, "Poligono eliminato")
    const layers = e.layers;
    layers.eachLayer(async (layer) => {
      const name = layer.options.name;
      if (name) {
        setPolygons((prev) => prev.filter((polygon) => polygon.name !== name));
        console.log("Poligono eliminato:", name);
        await deletArea(name);
      }
    });
  };

// Assicurati che debounce crei una funzione stabile
const debouncedHandleEdited = useRef(debounce(async (event) => {
  console.log("Modifica dei poligoni iniziata:", event);

  const layers = event.layers;
  let updatedPolygons = [...polygons];

  layers.eachLayer(async (layer) => {
    const polygonCoordinates = layer.getLatLngs()[0].map((point) => ({
      lat: point.lat,
      lng: point.lng,
    }));

    if (polygonCoordinates.length > 0) {
      polygonCoordinates.push(polygonCoordinates[0]); // Chiudi il poligono
    }

    const name = layer.options.name || prompt("Inserisci il nome per il poligono modificato:");
    if (name) {
      layer.options.name = name; // Imposta il nome sul layer

      updatedPolygons = updatedPolygons.map((polygon) =>
        polygon.name === name
          ? { ...polygon, coordinates: polygonCoordinates }
          : polygon
      );

      console.log("Poligono aggiornato:", { name, coordinates: polygonCoordinates });

      // Aggiorna nel backend
      await createOrUpdateArea(
        polygonCoordinates.map(({ lat, lng }) => [lng, lat]),
        name
      );
    }
  });

  setPolygons(updatedPolygons);
  giveMeAree(); // Sincronizza i dati dal backend
}, 500)).current;


  return (
    <MapContainer
      center={[43.324, 13.538]} // Centro iniziale
      zoom={9}
      style={{ height: "70vh", width: "100%" }}
      ref={mapRef}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; OpenStreetMap contributors"
      />

      {/* Disegno dei perimetri */}
      <FeatureGroup  key={JSON.stringify(polygons)}>
        <EditControl
          position="topright"
          onCreated={handleCreated}
          onEdited={debouncedHandleEdited}
          onDeleted={handleDeleted}
          draw={{
            rectangle: false,
            circle: false,
            marker: false,
            polyline: false,
            circlemarker: false,
          }}
        />
        {polygons.map((polygon, index) => (
          <Polygon
            key={index}
            positions={polygon.coordinates.map(({ lat, lng }) => [lat, lng])}
            color="blue"
            name={polygon.name}
          >
            <Popup>{polygon.name}</Popup>
          </Polygon>
        ))}
      </FeatureGroup>


      {/* Marker dei punti */}
      {points && points.map((point, index) => {
        const [lat, lng] = getOffsetPosition(point.lat, point.lng, index); // Calcola nuova posizione con offset
        const insidePolygon = isPointInPolygon(point, polygons); // Controlla se il punto è in un perimetro

        return (
          <Marker key={index} position={[lat, lng]}>
            <Popup>
              <div>
                <p>Bagno: {point.info}</p>
                {insidePolygon ? (
                  <p>Nel perimetro: {insidePolygon}</p>
                ) : (
                  <p>Fuori da tutti i perimetri</p>
                )}
              </div>
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
};

export default MapWithDrawing;
