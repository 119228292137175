// client/src/components/CheckoutButton.js
import React from 'react';
import axios from 'axios';
import {checkout} from '../../api/pagamenti/api';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51PhTp72LGbSg08mVUEpin8ziHVtUIVwQOfx1Z9fB2c73ypjOIs5EdPfRe2EZv06tAuShvkOJOXFd4o0GdELeeYcy00f2c7hdCb');

//const CheckoutButton = ({ amount, accountId }) => {
  const CheckoutButton = ({ utenteId, bagniId, dataInizio, dataFine,tipologiaId,impiego}) => {
  /*utenteId, bagniId, dataInizio, dataFine,tipologiaId,impiegoId*/
  const handleCheckout = async () => {
    const stripe = await stripePromise;
    try {
      let response = await checkout(utenteId, bagniId, dataInizio, dataFine,tipologiaId,impiego);
      const { url } = response;
      window.location.href = url;
    } catch (error) {
      console.error('Errore durante il checkout:', error);
    }
    /*try {
      const response = await axios.post('http://localhost:5000/stripe/create-checkout-session', {
        amount,
        accountId,
      });

      const { url } = response.data;
      window.location.href = url;
    } catch (error) {
      console.error('Errore durante il checkout:', error);
    }*/
  };

  return <button onClick={handleCheckout}>Checkout con Stripe</button>;
};

export default CheckoutButton;
