// Calendario.js
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './css/DataPicker.css'
function Calendario({getData,resetData,resetDataChage,start,end,prenotazione,showDateSemplificate=true}) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));

  useEffect(() => {
    getData(startDate,endDate)
  }, [startDate, endDate]);

  useEffect(() => {
    if(resetData){
      setStartDate(null)
      setEndDate(null)
      resetDataChage(false)
    }
  }, [resetData]);

  useEffect(() => {
    if(prenotazione){
      // fine prenotazione tra 2 anni
      let end = new Date()
      end.setFullYear(end.getFullYear() + 2)
      
      setEndDate(new Date(end))
    }
  }, [prenotazione]);
  const handleToday = () => {
    let tomorrow = new Date();
    let today = new Date();
    today.setDate(today.getDate() -1);
    tomorrow.setDate(tomorrow.getDate() + 1);
    setStartDate(today)
    setEndDate(tomorrow)
  }

  const handleTomorrow =() => {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let tomorrow2 = new Date();
    tomorrow2.setDate(tomorrow2.getDate() + 2);
    setStartDate(tomorrow)
    setEndDate(tomorrow2)
  }

  const handleThisWeek = ()=>  {
    let today = new Date();
    let startWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    let endWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));
    setStartDate(startWeek)
    setEndDate(endWeek)
  };
  return (
    <div className='main-data-picker'>
      <div className='data-picker-container'>
        <div className='cont-inp-datepicker'>
          <label>Inizio:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            placeholderText="Seleziona la data di inizio"
          />
        </div>

        <div className='cont-inp-datepicker'>
          <label>Fine:</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="dd/MM/yyyy"
            placeholderText="Seleziona la data di fine"
          />
        </div>
      </div>
     {showDateSemplificate && <div className='btn-filter-table'>
      {/* creo un bottone per selexionare tra oggi e domani*/}
        <button className="btn-table" onClick={handleToday} >Oggi</button>
        {/* creo un bottone per selexionare tra domani e dopodomani*/}
        <button className="btn-table" onClick={handleTomorrow} >Domani</button>
        {/* creo un bottone per selexionare questa settimana */}
        <button className="btn-table" onClick={handleThisWeek} >Questa Settimana</button>
        {/* creo un bottone per selexionare la prossima settimana */}
      </div> }
      <div>
        {/*startDate && endDate && (
          <p>
            {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
          </p>
        )*/}
      </div>
    </div>
  );
}

export default Calendario;
