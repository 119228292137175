import { useState, useEffect } from 'react';
import { giveMeGiroPilizieUtente } from '../../api/prodotti/api';
import MostraTutti from '../map/MostraTutti';
import MostraTabella from './tabelle/MostraGiro'; // Correzione del nome dell'import
import ModalAssociaConsegna from './modali/ModalAssociaPulizie';
import  {assegnaCollaboratorePulizia} from '../../api/collaboratori/api';
import Calendario from './Calendario';
import table from '../../image/table.png'; // Importa l'immagine per il bottone
import map from '../../image/map.png';
import './css/GiroPulizie.css';

const GiroPulizie = ({ option, startDate,endDate }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [mostraMappa, setMostraMappa] = useState(false);
  const [mostraTabella, setMostraTabella] = useState(false);
  //const [startDate, setStartDate] = useState();
  //sconst [endDate, setEndDate] = useState();



  const [modalAssociaConsegna, setModalAssociaConsegna] = useState(false);
  const [datiAssociazione, setDatiAssociazione] = useState();


  const getAllBagniUtente = async (time,distanza,area) => {

    
    if (option == 'bagni'){
        try {
        const response = await giveMeGiroPilizieUtente(startDate, endDate,time,distanza,area);
        console.log(response);
        let dataTmap = [];
        response.forEach((element) => {
            dataTmap.push({
            lat: element.posizione.coordinates[1],
            lng: element.posizione.coordinates[0],
            info: element.bagnoId?.numero,
            ora: element.oraPulizia,
            stato: !element.isPulito ? 'no' : 'si',
            ordineId: element.ordineId || '',
            cliente: element.prenotazioneId?.nomePrenotazione,
            collaboratoreId: element.collaboratoreId?.nome || 'Non assegnato',
            area: element.area || 'nessuna area',
            nota: element.nota || 'nessuna nota',
            pulizia_id: element._id,
            });
        });

        setData(dataTmap);
        setLoading(false);
        setMostraTabella(true);
        } catch (error) {
        setError(true);
        setErrorText(error.message);
        setLoading(false);
        }
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      getAllBagniUtente();
    }
  }, [option, startDate, endDate]);


  /*const getData = (startDate,endDate) => {
    if (startDate) {
        setStartDate(startDate)
    }
    if (endDate) {
        setEndDate(endDate)
    }
}*/

  const updateData = () => {
    getAllBagniUtente();
  }

  const recalculate = (time,distanza,area) => {
    getAllBagniUtente(time,distanza,area);
  }

  const apriModalAssociaConsegna = (data) => {
    // qui ci sono i dati del bagno per la pulizia
    console.log(data, 'data')
    setDatiAssociazione(data);
    setModalAssociaConsegna(true);
  }

  const ChiudiModalAssociaConsegna = () => {
    setModalAssociaConsegna(false);
  };

  const inviaDatiAssociazione = async(data,collaborateroId) => {

    const {ordine,singolo} = data;
    // se entrambi i valori sono true o false restituisco un allert dicendo che non è possibile associare
    if (ordine && singolo || !ordine && !singolo) {
      alert('Seleziona solo un tipo di associazione');
      return;
    }
    
    console.log(ordine,singolo, 'data');
    //const uniqOrdiniId = [...new Set(datiAssociazione.map(item => item.ordineId))];
    const info = datiAssociazione.map(item => {
      return {
        ordineId: item.ordineId,
        etichetta: item.info,
        ora: item.ora,
      }
    });

    let unicaPulizia = singolo ? true : false;
    //console.log(uniqOrdiniId);
    // per ogni uniqOrdiniId effetuo la chiamata a associaCollaboratoreConsegne
  
    for (let i = 0; i < info.length; i++) {
      const ordineId = info[i].ordineId;
      // estraggo tutti 
      try {
        const response = await assegnaCollaboratorePulizia(collaborateroId,ordineId,info[i].etichetta,info[i].ora,unicaPulizia);
        console.log(response);
        //recalculate();
      } catch (error) {
        console.log(error);
      }
    }
    setModalAssociaConsegna(false);
  }


  return (
    <div className='giro'>
      {/*<Calendario getData={getData}/>*/ }
      <ModalAssociaConsegna isOpen={modalAssociaConsegna} onClose={ChiudiModalAssociaConsegna} onSubmit={inviaDatiAssociazione} />
      {loading && <p>Caricamento...</p>}
      {error && <p>Si è verificato un errore: {errorText}</p>}
      {!loading && !error && (
        <div>
          {data.length === 0 && <p>Nessun bagno trovato</p>}
          {data.length > 0 && (
            <div>
              <div className='cont-button-bagni-giro'>
                {/* Corretto l'uso delle funzioni di stato per mostrare mappa e tabella */}
                <img src={map} title="mostra mappa" className='pointer-img' alt='mappa'onClick={() => {
                    setMostraMappa(true);
                    if (mostraTabella) {
                      setMostraTabella(false);
                    }
                  }} />
                <img src={table} title="mostra tabella" className='pointer-img' alt='tabella' onClick={() => {
                    setMostraTabella(true);
                    if (mostraMappa) {
                      setMostraMappa(false);
                    }
                  }} />
                {/*<button
                  onClick={() => {
                    setMostraMappa(!mostraMappa);
                    if (mostraTabella) {
                      setMostraTabella(false);
                    }
                  }}
                >
                  Mappa
                </button>
                <button
                  onClick={() => {
                    setMostraTabella(!mostraTabella);
                    if (mostraMappa) {
                      setMostraMappa(false);
                    }
                  }}
                >
                  Tabella
                </button>*/}
              </div>
              {mostraMappa && <MostraTutti points={data} />}
              {mostraTabella && <MostraTabella 
              points={data} 
              recalculate={recalculate}
              modalConsegneIsOpen={modalAssociaConsegna}
              apriModalAssociaConsegna={apriModalAssociaConsegna} 
              updateData={updateData} 
              />}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GiroPulizie;
