// client/src/pages/CheckoutSuccess.js
import React from 'react';

const CheckoutSuccess = () => (
  <div>
    <h1>Pagamento completato con successo!</h1>
    <p>Grazie per aver completato il pagamento.</p>
  </div>
);

export default CheckoutSuccess;
