import React, { useState, useEffect } from 'react';
import './css/Magazzino.css'; // Assicurati di creare e importare il file CSS
import axios from 'axios';
import {createMagazzino,getMagazzino,deleteMagazzino} from '../../api/magazzino/api';

const Magazzino = () => {
    const [formData, setFormData] = useState({
        nome: '',
        indirizzo: '',
        address: ''
    });
    const [records, setRecords] = useState([
    ]);
    
    // Stato per i record salvati
    const [editingRecord, setEditingRecord] = useState(null); // Record in modifica
    const [googleMapsUrl, setGoogleMapsUrl] = useState('');
    const [debouncedAdress, setDebouncedAdress] = useState('');
    const [latitudine, setLatitudine] = useState('');
    const [longitudine, setLongitudine] = useState('');
    const [city, setCity] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAdressChange = (e) => {
        setFormData({
            ...formData,
            address: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (editingRecord !== null) {
            const updatedRecords = records.map((record, index) =>
                index === editingRecord ? { ...record, ...formData } : record
            );
            setRecords(updatedRecords);
            setEditingRecord(null);
        } else {
            setRecords([...records, { ...formData, latitudine, longitudine }]);
        }
        await createMagazzino(formData.nome, formData.address, latitudine, longitudine);

        setFormData({ nome: '', indirizzo: '', address: '' });
        setGoogleMapsUrl('');
        setLatitudine('');
        setLongitudine('');
        setCity('');

        getDataFromMagazzino();
    };

    const handleEdit = (index) => {
        setEditingRecord(index);
        setFormData(records[index]);
    };

    const handleDelete = async (index) => {
        const id = records[index]._id;
        const updatedRecords = records.filter((_, i) => i !== index);
        await deleteMagazzino(id);
        setRecords(updatedRecords);
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedAdress(formData.address);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [formData.address]);

    const getDataFromMagazzino = async () => {
        const data = await getMagazzino();
        setRecords(data);

    }
    useEffect(() => {
        getDataFromMagazzino();
    }, []);
        

    async function getCoordinates(address) {
        try {
            const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                params: {
                    format: 'json',
                    q: address
                }
            });

            const data = response.data;
            if (data.length > 0) {
                setLatitudine(data[0].lat);
                setLongitudine(data[0].lon);
                setCity(data[0].name);
                setGoogleMapsUrl(`https://www.google.com/maps?q=${data[0].lat},${data[0].lon}&z=15&output=embed`);
            } else {
                console.log('Nessun risultato trovato');
            }
        } catch (error) {
            console.error('Errore durante la richiesta:', error);
        }
    }

    useEffect(() => {
        if (debouncedAdress) {
            getCoordinates(debouncedAdress);
        }
    }, [debouncedAdress]);

    return (
        <div className="magazzino-container">
            <h2 className="magazzino-title">Gestione Magazzino</h2>
            <form className="magazzino-form" onSubmit={handleSubmit}>
                <div className="magazzino-input-container">
                    <label htmlFor="nome" className="magazzino-label">Nome Magazzino:</label>
                    <input
                        type="text"
                        id="nome"
                        name="nome"
                        className="magazzino-input"
                        value={formData.nome}
                        onChange={handleChange}
                    />
                </div>
                <div className="magazzino-input-container">
                    <label htmlFor="indirizzo" className="magazzino-label">Indirizzo Magazzino:</label>
                    <input
                        type="text"
                        id="indirizzo"
                        name="indirizzo"
                        className="magazzino-input"
                        value={formData.address}
                        onChange={handleAdressChange}
                    />
                </div>
                {googleMapsUrl && (
                    <iframe
                        title="Google Maps"
                        src={googleMapsUrl}
                        width={window.innerWidth < 500 ? "300" : "500"}
                        height={window.innerWidth < 500 ? "250" : "500"}
                        allowFullScreen=""
                        loading="lazy"
                        style={{ border: 0 }}
                    ></iframe>
                )}
                <button type="submit" className="magazzino-button">
                    {editingRecord !== null ? 'Modifica' : 'Crea'}
                </button>
            </form>

            <h3 className="magazzino-title">Tabella magazzino</h3>
            <table className="magazzino-table">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Indirizzo</th>
                        <th>Latitudine</th>
                        <th>Longitudine</th>
                        <th>Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {records.map((record, index) => (
                        <tr key={index}>
                            <td>{record.nome}</td>
                            <td>{record.address}</td>
                            <td>{record.latitudine}</td>
                            <td>{record.longitudine}</td>
                            <td>
                                <button className="magazzino-button" onClick={() => handleEdit(index)}>Modifica</button>
                                <button className="magazzino-button delete" onClick={() => handleDelete(index)}>Elimina</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Magazzino;
