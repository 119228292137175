import  { useState, useEffect } from 'react'

import { getCostoPulizia,costoPulizia,getProdotti,getImpieghi } from '../../api/prodotti/api'

import MostraPrezzoPulizie from './tabelle/MostraPrezzoPulizie'
import './css/GestisciPrezzo.css'


const GestisciPrezzo = ({option}) => {
    const [prodotti, setProdotti] = useState([])
    const [impieghi, setImpieghi] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')

    const [prezzo, setPrezzo] = useState('')
    const [impiegoId, setImpiegoId] = useState('')
    const [periodicita, setPeriodicita] = useState('')
    const [costiPulizia, setCostiPulizia] = useState([{}])


    const getProdottiFromApi = async () => {
        try {
            const prodottiApi = await getProdotti()
            setProdotti(prodottiApi)
            setLoading(false)
        } catch (error) {
            setError(true)
            setErrorText(error.message)
            setLoading(false)
        }
    }

    const getImpieghiFromApi = async () => {
        try {
            const impieghiApi = await getImpieghi()
            setImpieghi(impieghiApi)
            setLoading(false)
        } catch (error) {
            setError(true)
            setErrorText(error.message)
            setLoading(false)
        }
    }


    const giveMePrezzi = async () => {
        try {
            const costiPuliziaApi = await getCostoPulizia()

            setCostiPulizia(costiPuliziaApi)
            setLoading(false)
        } catch (error) {
            setError(true)
            setErrorText(error.message)
            setLoading(false)
        }
    }


    useEffect(() => {
        getProdottiFromApi()
        getImpieghiFromApi()
        giveMePrezzi()
    }, [option])


    const createPrezzo = async () => {
        let prodottoId = prodotti.filter((prodotto) => prodotto.nome == option)[0]._id

            const obj = {
                prezzo: prezzo,
                impiegoId: impiegoId,
                tipologiaId: prodottoId,
                periodicita: periodicita,
            }
            try {
                await costoPulizia(obj)
                getCostoPulizia()                
            } catch (error) {
                setError(true)
                setErrorText(error.message)
            }
    }

    return (
        <div className='gestisciPrezzo'>
            <div className='prezzo-pulizia'>
                <h3>Costi Pulizia</h3>
                <label>Impiego</label>
                    <select className='prezzo-select' onChange={(e)=>setImpiegoId(e.target.value) }>
                        {impieghi.map((impiego) => (
                            <option key={impiego?._id} value={impiego?._id}>{impiego?.impiego[0]}</option>
                        ))}
                    </select>
                    <label>Tipologia</label>
                    <select className='prezzo-select'>
                        {prodotti.map((prodotto) => (
                            prodotto.nome == option && 
                            <option key={prodotto._id} value={prodotto._id}>{prodotto.descrizione}</option>
                        ))}
                    </select>
                    <label>Prezzo</label>
                    <input type='number'   
                    onChange={(e) => {
                        const valore = e.target.value.replace(/,/g, '.');
                        setPrezzo(parseFloat(valore) || '');
                    }}/>
                    <label>Periodicità</label>
                    <select className='prezzo-select' onChange={(e)=>setPeriodicita(e.target.value)}>
                        <option value='1g'>una volta al giorno</option>
                        <option value='2g'>due volte al giorno</option>
                        <option value='3g'>tre volte al giorno</option>
                        <option value='4g'>quattro volte al giorno</option>
                        <option value='1s'>una volta a settimana</option>
                        <option value='1m'>una volta ogni 15 giorni</option>
                    </select>
                    <button onClick={createPrezzo} className='btn-prezzo-gestore'>
                        Aggiungi/Modifica
                    </button>
                    <MostraPrezzoPulizie dataBagni={costiPulizia}/>
            </div>
        </div>
    )
}

export default GestisciPrezzo