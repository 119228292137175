import { getApi } from "../api";


export const createCollaboratore = async (nome, email, password, max, typeOfJob, maxDistnza, address, latitudine, longitudine,area) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}collaboratori/createCollaboratore`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ nome, email, password, max, typeOfJob, maxDistnza, address, latitudine, longitudine,area })
    });
    const data = await response.json();
    return data;
    
}

export const modifyCollaboratore = async (id, collaboratore) => {
    const response = await getApi().patch(`/collaboratori/modifyCollaboratore/${id}`, collaboratore);
    return response.data;
}

export const deleteCollaboratore = async (id) => {
    const token = localStorage.getItem('token');
    try {

        const response = await fetch(`${getApi()}collaboratori/deleteCollaboratore/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
            },
        });
        console.log(response);
    } catch (error) {
        console.log(error);
        
    }

}

export const getCollaboratori = async () => {
    const token = localStorage.getItem('token');

    const response = await fetch(`${getApi()}collaboratori/getCollaboratori`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
    });
    const data = await response.json();
    return data;
}

export const associaCollaboratoreConsegne = async (idCollaboratore,idOrdine,consegna,ritiro) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}collaboratori/associaCollaboratoreConsegne`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ idCollaboratore,idOrdine,consegna,ritiro })
    });
    const data = await response.json();
    return data;
}

export const assegnaCollaboratorePulizia = async (idCollaboratore, idOrdine, numero, oraPulizia, unicaPulizia) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}collaboratori/assegnaCollaboratorePulizia`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ idCollaboratore, idOrdine, numero, oraPulizia, unicaPulizia })
    });
    const data = await response.json();
    return data;
}


export const loginCollaboratore = async (password,param) => {
    const response = await fetch(`${getApi()}collaboratori/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password,param })
    });
    const data = await response.json();
    return data;
}

export const tokenExpired = async (token) => {
    const response = await fetch(`${getApi()}collaboratori/tokenExpired`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token })
    });
    return response;
}
