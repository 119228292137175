import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLogin: false, // Stato iniziale della variabile
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLogin = true; // Impostiamo `isLogin` a true quando l'utente esegue il login
    },
    logout: (state) => {
      state.isLogin = false; // Impostiamo `isLogin` a false quando l'utente esegue il logout
    },
  },
});

// Esportiamo le azioni
export const { login, logout } = authSlice.actions;

// Esportiamo il reducer
export default authSlice.reducer;
