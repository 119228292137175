import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './css/mostratutti.css'; // Importa il tuo file CSS per lo stile

const MostraTutti = ({ points }) => {
  // Controllo se esistono punti da mostrare
  const [newPoints, setPoints] = useState(points);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  /*if (!points || points.length === 0) {
    return <p>No points to show</p>;
  }*/



  const getCity = async (lat, lon) => {
    try {
      const response = await axios.get(
        `http://api.openweathermap.org/geo/1.0/reverse?lat=${lat}&lon=${lon}&limit=1&appid=${process.env.REACT_APP_OPENWEATHERMAP_API_KEY}`
      );
      return response.data[0]?.name || 'Unknown location';
    } catch (error) {
      setError(true);
      setErrorText(error.message);
      return 'Unknown location';
    }
  };


  useEffect(() => {
    if (points?.length) {
      const getCityNames = async () => {
        let newPointsTmp = [];


        for (const point of points) {
          const city = !point.city? await getCity(point.lat, point.lng) : point.city;

          newPointsTmp.push({
            ...point,
            city,
          });
        }

        newPointsTmp = newPointsTmp.sort((a, b) => a.info - b.info);
        setPoints(newPointsTmp);
      };

      getCityNames();
    }
  }, [points]);


  return (
    <div className="table-container">
      <table className="responsive-table">
        <thead>
          <tr>
            <th>Latitudine</th>
            <th>Longitudine</th>
            <th>paese</th>
            <th>Informazioni</th>
          </tr>
        </thead>
        <tbody>
          {newPoints.map((point, index) => (
            
            <tr key={index}>
              <td>{point.lat ? point.lat : <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>}</td>
              <td>{point.lng ?  point.lng:  <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>}</td>
              <td>{point.city ? point.city: <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>}</td>
              <td>{point.info ? point.info : <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MostraTutti;
