import { useEffect, useState } from "react";
import axios from "axios";
import "./css/pulizie.css";

const Pulizie = ({ pulizia }) => {
  const headerTable = [
    "giorno",
    "bagno",
    "luogo",
    "responsabile",
    "pulito",
    "nota",
    "image",
  ];
  const [sortedPulizia, setSortedPulizia] = useState([]);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // imposta formato 24 ore
  };

  const formatedDate = (data) => {
    const date = new Date(data);
    return date.toLocaleDateString("it-IT", options);
  };

  useEffect(() => {
    sortPuliziaByDate();
  }, [pulizia]);

  const sortPuliziaByDate = () => {
    if (pulizia) {
      let puliziaSortedTmp = [...pulizia];
      puliziaSortedTmp.sort(
        (a, b) => new Date(a.oraPulizia) - new Date(b.oraPulizia)
      );
      setSortedPulizia(puliziaSortedTmp);
    }
  };

  const getCity = async (lat, lon) => {
    try {
      const response = await axios.get(
        `http://api.openweathermap.org/geo/1.0/reverse?lat=${lat}&lon=${lon}&limit=1&appid=${process.env.REACT_APP_OPENWEATHERMAP_API_KEY}`
      );
      return response.data[0]?.name || "Unknown location"; // Ritorno il nome della città
    } catch (error) {
      setError(true);
      setErrorText(error.message);
      return "Unknown location"; // In caso di errore, ritorno un valore di fallback
    }
  };

  const CityCell = ({ coordinates }) => {
    const [city, setCity] = useState("Loading...");

    useEffect(() => {
      const fetchCity = async () => {
        if (coordinates?.length === 2) {
          const [lon, lat] = coordinates;
          const cityName = await getCity(lat, lon);
          setCity(cityName);
        } else {
          setCity("Unknown location");
        }
      };

      fetchCity();
    }, [coordinates]);

    return <td>{city}</td>;
  };

  const createTable =
    sortedPulizia && sortedPulizia.length > 0 ? (
      <table className="table-client">
        <thead className="thead-client">
          <tr>
            {headerTable.map((key, index) => (
              <th key={index}>{key.toUpperCase()}</th>
            ))}
          </tr>
        </thead>
        <tbody className="tbody-client">
          {sortedPulizia.map((puli, index) => (
            <tr key={index}>
              <td data-label="Data">{formatedDate(puli.oraPulizia)}</td>
              <td data-label="Bagno">{puli?.bagnoId?.numero || 'in assegnazione'}</td>
              <CityCell
                coordinates={puli?.prenotazioneId?.posizione?.coordinates}
                data-label="Città"
              />
              <td data-label="Collaboratore">{puli?.collaboratoreId?.nome || 'in assegnazione'}</td>
              <td data-label="Pulito">{puli.isPulito ? "Sì" : "No"}</td>
              <td data-label="Nota">{puli.nota || "Nessuna nota"}</td>
              <td data-label="Immagine">
                {puli.image ? (
                  <img src={puli.image} alt="Immagine pulizia" />
                ) : (
                  "Nessuna immagine"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p>Nessun dato disponibile</p>
    );

  return (
    <div className="main-pulizie">
      <h1>Pulizie</h1>
      {error && <p className="error-text">Errore: {errorText}</p>}
      {createTable}
    </div>
  );
};

export default Pulizie;
