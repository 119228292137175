import  { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {createUser,loginUser} from '../../api/user/api';
import './css/LoginGestore.css';
import {useDispatch } from 'react-redux';
import { login } from '../../redux/slice/authSlice';

const userIsLogin = true;
const LoginGestore = () => {

    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(true)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [email, setEmail] = useState('')

    const dispatch = useDispatch();


    const handleLogin = async (e) => {
        e.preventDefault()
        console.log('Login')
        let userLogin = await loginUser({email, password})
        if(userLogin?.token){
            localStorage.setItem('token', userLogin.token)
            dispatch(login());
            navigate('/gestore/noleggio')
        }else{
            console.log('Errore')
        }
        
        
    }

    const handleRegister = async (e) => {
        e.preventDefault()
        console.log('Register')
        let userLogin = await createUser({username, password, confirmPassword, email})
        if(userLogin.result){
            console.log(userLogin.result)
            navigate('/gestore/noleggio')
        }else{
            console.log('Errore')
        }
    }

    useEffect(() => {
        if(localStorage.getItem('token')){
            navigate('/gestore/noleggio')
        }
    }, [])

    return (
        <div className="main-login-gestore">
            { isLogin ? <>
            <h3 className="header-page">Login</h3>
            <form>
                <label>Email</label>
                <input type="text"  onChange={(e)=>setEmail(e.target.value)} />
                <label>Password</label>
                <input type="password" onChange={(e)=>setPassword(e.target.value)} />
                <button className='button-generic' onClick={handleLogin} >Login</button>
            </form>
            {/*<p>Non hai un account? <span className='registrati-login' onClick={() => setIsLogin(false)}>Registrati</span></p>*/}
            </> : <>
            <h3 className="header-page">Registrati</h3>
            <form>
                <label>Email</label>
                <input type="email" onChange={(e)=>setEmail(e.target.value)} />
                <label>Username</label>
                <input type="text" onChange={(e)=>setUsername(e.target.value)} />
                <label>Password</label>
                <input type="password" onChange={(e)=>setPassword(e.target.value)} />
                <label>Conferma Password</label>
                <input type="password" onChange={(e)=>setConfirmPassword(e.target.value)} />
                <button className='button-generic' type="submit"  onClick={handleRegister} >Registrati</button>
            </form>
            <p>Hai già un account? <span className='registrati-login' onClick={() => setIsLogin(true)}>Login</span></p>
             </>
             }
        </div>
    )
}

export default LoginGestore;