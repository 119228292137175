// client/src/pages/OnboardingSuccess.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const OnboardingSuccess = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/dashboard'); // Cambia il percorso con quello desiderato
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>Onboarding completato con successo!</h1>
      <p>Il tuo account Stripe Connect è stato configurato correttamente.</p>
      <button onClick={handleRedirect} style={{ marginTop: '20px' }}>
        Vai al Dashboard
      </button>
    </div>
  );
};

export default OnboardingSuccess;
