import  { useEffect, useState } from 'react'



const Liberi = ({option}) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')

    useEffect(() => {
        /*const fetchData = async () => {
            try {
                const response = await fetch(`http://localhost:3001/${option}/liberi`)
                const data = await response.json()
                setData(data)
                setLoading(false)
            } catch (error) {
                setError(true)
                setErrorText(error.message)
                setLoading(false)
            }
        }*/

        const fetchData =  () => {
            setData(10)
        }
        fetchData()
    }, [option])

    return (
        <div className='liberi'>
            <h2>Liberi</h2>
            <p>hai {option} {data} liberi</p>
            {/*
                aggiungere calendario per selezionare un periodo 
                e visualizzare i prodotti liberi in quel periodo
            */}
        </div>
    )
}

export default Liberi

