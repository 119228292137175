import LoginGestore from "./LoginGestore";


const Gestore = () => {
    return (
        <div>
            <p className="benvenuto-paragraph">Benvenuto, effetua il login o registrati per iniziare a vendere e gestire i tuoi servizi</p>
            <LoginGestore />
        </div>
    )
}

export default Gestore;