
import {useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import GestioneCollaboratori from './GestioneCollaboratori';

import {getProdotti} from '../../api/prodotti/api';


import './css/Noleggio.css';

const Collaboratori = () => {
    const [goToDashboard, setGoToDashboard] = useState(false)
    const [selectedOption, setSelectedOption] = useState('')
    const [viewReport, setViewReport] = useState('aggiungi')
    const [prodotti, setProdotti] = useState([])

    const navigate = useNavigate();

    const handleGoToDashboard = (goTo) => {

        if(goTo){
            navigate(goTo)
        }
    }
    const fetchData = async () => {

        let prodottiFetched = await getProdotti()
        if (prodottiFetched.message == 'Token expired'){
            localStorage.removeItem('token')
            navigate('/gestore/login')
        }else {
            setProdotti([...prodottiFetched])
            setSelectedOption(prodottiFetched[0].nome)
        }
    }

    useEffect(() => {
        if (!prodotti.length){
            fetchData()
        }
    },[prodotti])


    return (
        <div className='noleggio'>
            <div className="noleggio-container-data">
        <div className="noleggio-main-container">
                <div className="noleggio-data-barralaterale">
                    <div className="noleggio-data-barralaterale-titolo" onClick={(e)=>setViewReport('aggiungi')} >
                        <h3>Aggiungi/Modifica Collaboratori</h3>
                    </div>
                </div>
                <div className="noleggio-data-corpo-centrale">
                    { viewReport === 'aggiungi' &&
                        <div className="noleggio-data-barralaterale-corpo-titolo">
                            <GestioneCollaboratori option={selectedOption}/>
                        </div>
                    }
                </div>
            </div>
        </div>
        </div>
    );
    }

export default Collaboratori;