import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './css/GestioneCollaboratori.css';
import { createCollaboratore,getCollaboratori,deleteCollaboratore} from '../../api/collaboratori/api'
import { getAreeUtente } from '../../api/prodotti/api';
import Multiselect from 'multiselect-react-dropdown';


const GestioneCollaboratori = () => {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [max, setMax] = useState(0);
    const [typeOfJob, setTypeOfJob] = useState('pulizie'); // Default: 'pulizie'
    const [maxDistnza, setMaxDistnza] = useState(0);
    const [collaboratori, setCollaboratori] = useState([]);
    const [selectedCollaboratore, setSelectedCollaboratore] = useState(null); // Per la modifica
    const [address, setAddress] = useState('')
    const [debouncedAdress, setDebouncedAdress] = useState('')
    const [googleMapsUrl, setGoogleMapsUrl] = useState('')

    const [area, setArea] = useState([])
    const [selectedArea, setSelectedArea] = useState([])

    const [indexToDel, setIndexToDel] = useState()
    const [showModalElimina, setShowModalElimina] = useState(false)


    const [areaConsegne, setAreaConsegne] = useState([])
    const [selectedAreaConsegne, setSelectedAreaConsegne] = useState([])

    const [indexToDelConsegne, setIndexToDelConsegne] = useState()
    const [showModalEliminaConsegne, setShowModalEliminaConsegne] = useState(false)




    const [latitudine, setLatitudine] = useState('')
    const [longitudine, setLongitudine] = useState('')



    const giveMeAree = async () => {
        let aree = await getAreeUtente()
        let tmparee = []
        aree.forEach((area) => {
            tmparee.push({name: area.nome, id: area.nome})
        })
        setArea(tmparee)
        setAreaConsegne(tmparee)
    }





    useEffect(() => {
        if (debouncedAdress) {
            getCoordinates(debouncedAdress);
        }
        }, [debouncedAdress]);

    const giveMeCollaboratori = async () => {

        let collaboratori = await getCollaboratori()
        setCollaboratori(collaboratori)
        
    }
    useEffect(() => {
        if (collaboratori.length === 0) {
            giveMeCollaboratori()
        }
        if(area.length === 0){
            giveMeAree()
        }
    }, []);

    async function getCoordinates(address) {
        try {
            const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                params: {
                    format: 'json',
                    q: address
                }
            });
            
            const data = response.data;
            if (data.length > 0) {
                console.log(`Latitudine: ${data[0].lat}, Longitudine: ${data[0].lon}`);
                setLatitudine(data[0].lat)
                setLongitudine(data[0].lon)
                setGoogleMapsUrl(`https://www.google.com/maps?q=${data[0].lat},${data[0].lon}&z=15&output=embed`);
            } else {
                console.log('Nessun risultato trovato');
            }
        } catch (error) {
            console.error('Errore durante la richiesta:', error);
        }
    }
    useEffect(() => {
        const handler = setTimeout(() => {
          setDebouncedAdress(address);
        }, 500); // 500ms di ritardo
    
        // Pulisce il timeout precedente se l'inputValue cambia prima che scada il tempo
        return () => {
          clearTimeout(handler);
        };
      }, [address]);


      const handleAdressChange = (e) => {
        setAddress(e.target.value);
      };
    // Funzione per aggiungere o modificare un collaboratore
    const addOrUpdateCollaboratore = async () => {
        let newCollaboratore ;

        if (typeOfJob == 'pulizie') {
         newCollaboratore = {
            nome,
            email,
            password,
            max,
            typeOfJob,
            maxDistnza,
            address,
            selectedArea,
        };
        } else {
            newCollaboratore = {
                nome,
                email,
                password,
                max,
                typeOfJob,
                maxDistnza,
                address,
                selectedArea:selectedAreaConsegne,
            };
        }

        if (selectedCollaboratore !== null) {
            // Modifica collaboratore esistente
            setCollaboratori(prevCollaboratori =>
                prevCollaboratori.map((collaboratore, index) =>
                    index === selectedCollaboratore ? newCollaboratore : collaboratore
                )
            );
            setSelectedCollaboratore(null); // Reset dopo la modifica
        } else {
            // Aggiungi nuovo collaboratore
            setCollaboratori([...collaboratori, newCollaboratore]);
        }
        let areaSelezionata = typeOfJob === 'pulizie' ? selectedArea : selectedAreaConsegne
        let prodottoAggiunto = await createCollaboratore(nome, email, password, max, typeOfJob, maxDistnza, address, latitudine, longitudine, areaSelezionata)
        // aggiungere api per aggiungere collaboratore inserire token, latitudine e longitudine alla richiesta

        console.log(prodottoAggiunto)
        // Resetta i campi di input
        resetForm();
        giveMeCollaboratori()
    };

    // Resetta il form
    const resetForm = () => {
        setNome('');
        setEmail('');
        setPassword('');
        setMax(0);
        setTypeOfJob('pulizie');
        setMaxDistnza(0);
        setAddress('')
        setSelectedArea([])
        setSelectedAreaConsegne([])

    };
    useEffect(() => {
        console.log(typeOfJob)
    }, [typeOfJob])
    // Funzione per gestire la modifica di un collaboratore
    const handleEditCollaboratore = (index) => {
        const collaboratore = collaboratori[index];
        console.log(collaboratore)
        setNome(collaboratore.nome);
        setEmail(collaboratore.email);
        setPassword(collaboratore.password);
        setMax(collaboratore.max);
        setTypeOfJob(collaboratore.typeOfJob);
        setAddress(collaboratore.address);
        setMaxDistnza(collaboratore.maxDistnza);
        setSelectedCollaboratore(index); // Memorizza l'indice del collaboratore da modificare
    
        // Gestisci le aree selezionate
        if (collaboratore.typeOfJob === 'consegne') {
            const areeConsegneSelezionate = collaboratore.area
                ? collaboratore.area.map((area) => area) // Verifica e mappa
                : [];
            setSelectedAreaConsegne(areeConsegneSelezionate);
        } else {
            const areePulizieSelezionate = collaboratore.area
                ? collaboratore.area.map((area) => area) // Verifica e mappa
                : [];
            setSelectedArea(areePulizieSelezionate);
        }
    };
    
    

    // Funzione per eliminare un collaboratore
    const handleDeleteCollaboratore = async (index) => {
        console.log(collaboratori[index])
        // Elimina il collaboratore
        await deleteCollaboratore(collaboratori[index]._id)
        setCollaboratori(collaboratori.filter((_, i) => i !== index));
    };


    const onSelectArea = (selectedList, selectedItem)=> {


        typeOfJob === 'consegne' ? 
        setSelectedAreaConsegne([...selectedAreaConsegne, selectedItem]) :
        setSelectedArea([...selectedArea, selectedItem])
        //setSelectedAreaConsegne([...selectedAreaConsegne, selectedItem])
    }
    
    const onRemoveArea = (selectedList, removedItem) => {
        if (typeOfJob === 'consegne') {
            setSelectedAreaConsegne((prev) => prev.filter((area) => area.id !== removedItem.id));
        } else {
            setSelectedArea((prev) => prev.filter((area) => area.id !== removedItem.id));
        }
    };
    


    return (
        <div className='container-collaboratori'>
            {showModalElimina &&
            
            <div className='container-modal-elimina-collaboratori'>
                <div className='modal-elimina-collaboratori'>
                    <h2>Sei sicuro di voler eliminare il collaboratore?</h2>
                    <div className='container-buttons-modal-elimina-collaboratori'>
                        <button onClick={() => {
                            handleDeleteCollaboratore(selectedCollaboratore)
                            setShowModalElimina(false)
                        }
                        }>Si</button>
                        <button onClick={() => setShowModalElimina(false)}>No</button>
                    </div>
                </div>
            </div>
            }
            <div className="form-collaboratori">
                <input type="text" 
                placeholder="Nome" 
                value={nome} 
                onChange={(e) => setNome(e.target.value)} 
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <label>Numero massimo di consegne giornaliere</label>
                <input
                    type="number"
                    placeholder="Numero massimo di consegne giornaliere"
                    value={max}
                    onChange={(e) => setMax(e.target.value)}
                />
                <label> inserisci la via con il formato: via, numero civico, città</label>
                <input
                    type="text"
                    value={address}
                    onChange={handleAdressChange}
                    placeholder="inserisci via, numero, citta..."
                />
                { googleMapsUrl ? 
                <iframe
                  title="Google Maps"
                  src={googleMapsUrl}
                  width="250"
                  height="150"
                  allowFullScreen=""
                  loading="lazy"
                  style={{ border: 0 }}
                ></iframe> : null}
                <label>massima distanza</label>
                <input 
                type="number" 
                placeholder="massima distanza" 
                value={maxDistnza} 
                onChange={(e) => setMaxDistnza(e.target.value)} 
                />
                <label>Tipologia di lavoro</label>
                <select value={typeOfJob} onChange={(e) => setTypeOfJob(e.target.value)}>
                    <option value="pulizie">Pulizie</option>
                    <option value="consegne">Consegne</option>
                </select>
                <label>Area</label>
                <Multiselect
                    options={typeOfJob === 'pulizie' ? area : areaConsegne} // Opzioni in base al tipo di lavoro
                    selectedValues={typeOfJob === 'pulizie' ? selectedArea : selectedAreaConsegne} // Preselezione corretta
                    onSelect={onSelectArea} // Funzione chiamata quando un'area viene aggiunta
                    onRemove={onRemoveArea} // Funzione chiamata quando un'area viene rimossa
                    displayValue="name" // Nome visibile nel dropdown
                />

                <button onClick={addOrUpdateCollaboratore}>
                    {selectedCollaboratore !== null ? 'Modifica Collaboratore' : 'Aggiungi Collaboratore'}
                </button>
            </div>

            <table className='table-collaboratori responsive-table' >
                <thead className='thead-collaboratori' id='thead-coll'>
                    <tr className='tr-collaboratori'>
                        <th className='th-collaboratori'>Nome</th>
                        <th className='th-collaboratori'>Email</th>
                        <th className='th-collaboratori'>Numero massimo di consegne</th>
                        <th className='th-collaboratori'>Tipologia di lavoro</th>
                        <th className='th-collaboratori'>Città</th>
                        <th className='th-collaboratori'>Massima distanza</th>
                        <th className='th-collaboratori'>Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {collaboratori.length && collaboratori.map((collaboratore, index) => (
                        <tr key={index} className='tr-collaboratori'>
                            <td className='td-collaboratori'>{collaboratore.nome}</td>
                            <td className='td-collaboratori'>{collaboratore.email}</td>
                            <td className='td-collaboratori'>{collaboratore.max}</td>
                            <td className='td-collaboratori'>{collaboratore.typeOfJob}</td>
                            <td className='td-collaboratori'>{collaboratore.address}</td>
                            <td className='td-collaboratori'>{collaboratore.maxDistnza}</td>
                            <td className='td-collaboratori'>
                                <div className='container-btn-collaboratori'>
                                    <button className='btn-collaboratori-modifica' onClick={() =>handleEditCollaboratore(index)}>Modifica</button>
                                    <button className='btn-collaboratori-elimina'  onClick={() => {
                                        setShowModalElimina(true)
                                        setSelectedCollaboratore(index)
                                    }}>Elimina</button>
                                </div>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default GestioneCollaboratori;
