
import {useState,useEffect,useCallback} from 'react';
import { useNavigate } from 'react-router-dom';

import Liberi from './Liberi';
import Totali from './Totali';
import Noleggiati from './Noleggiati';
import Prenotazioni from './Prenotazioni';
import Andamento from './Andamento';
import Trova from './Trova';
import GestisciPrezzo from './GestisciPrezzo';
import Aggiungi from './Aggiungi';
import Prenota from './Prenota';
import GiroPulizie from './GiroPulizie';
import Calendario from './Calendario';
import DropAree from './DropAree';
import {getProdotti} from '../../api/prodotti/api';


import './css/Noleggio.css';


const Noleggio = () => {
    const [goToDashboard, setGoToDashboard] = useState(false)
    const [selectedOption, setSelectedOption] = useState('')
    const [viewReport, setViewReport] = useState('totali')
    const [prodotti, setProdotti] = useState([])
    
    // Calendario // default value oggi e domani
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const navigate = useNavigate();

    const handleGoToDashboard = (goTo) => {

        if(goTo){
            navigate(goTo)
        }
    }
    const fetchData = async () => {

        let prodottiFetched = await getProdotti()
        if (prodottiFetched.message == 'Token expired'){
            localStorage.removeItem('token')
            navigate('/gestore/login')
        }else {
            setProdotti([...prodottiFetched])
            setSelectedOption(prodottiFetched[0].nome)
        }
    }

    useEffect(() => {
        if (!prodotti.length){
            fetchData()
        }
    },[prodotti])




    const getData = (startDate,endDate) => {
        if (startDate) {
            setStartDate(startDate)
        }
        if (endDate) {
            setEndDate(endDate)
        }
    }



    return (
        <div className='noleggio'>
            <div className="container-select">
                Filtri
                <div className="filtri-noleggio">
                    <div clasName="elementi-filtri">
                        <label className='label-prodotto'>Seleziona Prodotto</label>
                        <select onChange={(e)=>(setSelectedOption(e.target.value))}>
                            {prodotti.map((prodotto) => {
                                return <option value={prodotto.nome}>{prodotto.nome}</option>
                            }
                            )}
                        </select>
                    </div>
                    { (viewReport == 'pulizia' || viewReport == 'prenotazioni') &&
                      <div className="elementi-filtri">
                        <label className='label-prodotto'>Seleziona Data</label>
                        <Calendario getData={getData} />
                    </div>
                        }
                </div>
                </div>
            <div className="noleggio-container-data">
        <div className="noleggio-main-container">
                <div className="noleggio-data-barralaterale">
                        
                    <div className="noleggio-data-barralaterale-titolo" onClick={(e)=>setViewReport('totali')}>
                        <h5 className={`${viewReport =='totali'? 'actived-class':'' }`}>{selectedOption} totali</h5>
                    </div>
                    {/*<div className="noleggio-data-barralaterale-titolo" onClick={(e)=>setViewReport('liberi')} >
                        <h5 className={`${viewReport =='liberi'? 'actived-class':'' }`}>{selectedOption} Liberi</h5>
                    </div>
                    <div className="noleggio-data-barralaterale-titolo" onClick={(e)=>setViewReport('noleggiati')} >
                        <h5 className={`${viewReport =='noleggiati'? 'actived-class':'' }`}>{selectedOption} Noleggiati</h5>
                    </div>*/}
                    <div className="noleggio-data-barralaterale-titolo" onClick={(e)=>setViewReport('prenotazioni')} >
                        <h5 className={`${viewReport =='prenotazioni'? 'actived-class':'' }`}>{selectedOption} Consegne/Ritiri</h5>
                    </div>
                    <div className="noleggio-data-barralaterale-titolo" onClick={(e)=>setViewReport('pulizia')} >
                        <h5 className={`${viewReport =='pulizia'? 'actived-class':'' }`}>{selectedOption} Giro Pulizie</h5>
                    </div>
                    {/*<div className="noleggio-data-barralaterale-titolo" onClick={(e)=>setViewReport('andamento')} >
                        <h5 className={`${viewReport =='andamento'? 'actived-class':'' }`}>{selectedOption} Andamento</h5>
                    </div>
                    <div className="noleggio-data-barralaterale-titolo" onClick={(e)=>setViewReport('trova')} >
                        <h5 className={`${viewReport =='trova'? 'actived-class':'' }`}>Trova {selectedOption}</h5>
                    </div>
                    <div className="noleggio-data-barralaterale-titolo" onClick={(e)=>setViewReport('gestisciPrezzo')} >
                        <h5 className={`${viewReport =='gestisciPrezzo'? 'actived-class':'' }`}>Gestisci prezzo {selectedOption}</h5>
                    </div>*/}
                    <div className="noleggio-data-barralaterale-titolo" onClick={(e)=>setViewReport('aggiungi')}>
                        <h5 className={`${viewReport =='aggiungi'? 'actived-class':'' }`}>Aggiungi {selectedOption}</h5>
                    </div>
                    <div className="noleggio-data-barralaterale-titolo" onClick={(e)=>setViewReport('prenota')}>
                        <h5 className={`${viewReport =='prenota'? 'actived-class':'' }`}>Prenota {selectedOption}</h5>
                    </div>
                    <div className="noleggio-data-barralaterale-titolo" onClick={(e)=>setViewReport('disegna')}>
                        <h5 className={`${viewReport =='disegna'? 'actived-class':'' }`}>Assegna pulizie {selectedOption}</h5>
                    </div>
                </div>
                <div className="noleggio-data-corpo-centrale">
                    { viewReport === 'totali' &&
                        <div className="noleggio-data-barralaterale-corpo-titolo">
                            <Totali option={selectedOption}/>
                        </div>
                    }
                    { viewReport === 'liberi' &&
                        <div className="noleggio-data-barralaterale-corpo-titolo">
                            <Liberi option={selectedOption}/>
                        </div>
                    }
                    { viewReport === 'noleggiati' &&
                        <div className="noleggio-data-barralaterale-corpo-titolo">
                            <Noleggiati option={selectedOption}/>
                        </div>
                    }
                    { viewReport === 'prenotazioni' &&
                        <div className="noleggio-data-barralaterale-corpo-titolo">
                            <Prenotazioni option={selectedOption} startDate={startDate} endDate={endDate}/>
                        </div>
                    }
                    { viewReport === 'andamento' &&
                        <div className="noleggio-data-barralaterale-corpo-titolo">
                            <Andamento option={selectedOption}/>
                        </div>
                    }
                    { viewReport === 'trova' &&
                        <div className="noleggio-data-barralaterale-corpo-titolo">
                            <Trova option={selectedOption}/>
                        </div>
                    }
                    { viewReport === 'gestisciPrezzo' &&
                        <div className="noleggio-data-barralaterale-corpo-titolo">
                            <GestisciPrezzo option={selectedOption}/>
                        </div>
                    }
                    { viewReport === 'aggiungi' &&
                        <div className="noleggio-data-barralaterale-corpo-titolo">
                            <Aggiungi option={selectedOption}/>
                        </div>
                    }
                    { viewReport === 'prenota' &&
                        <div className="noleggio-data-barralaterale-corpo-titolo">
                            <Prenota option={selectedOption}/>
                        </div>
                    }
                    { viewReport === 'pulizia' &&
                        <div className="noleggio-data-barralaterale-corpo-titolo">
                            <GiroPulizie option={selectedOption} startDate={startDate} endDate={endDate}/>
                        </div>
                    }
                    { viewReport === 'disegna' &&
                        <div className="noleggio-data-barralaterale-corpo-titolo">
                            <DropAree option={selectedOption}/>
                        </div>
                    }
                </div>
            </div>
        </div>
        </div>
    );
    }

export default Noleggio;