import React, { useState,useEffect } from "react";
import Calendario from '../gestore/Calendario';
import FormCalcola from "./FormCalcola";
import Footer from "./Footer";
import CardServizi from "./CardServizi";
import  axios from 'axios'
import { getBagniNearbyHome } from '../../api/prodotti/api'
import Card from "./Card";
import RiepilogoOrdine from "./Riepilogo";

import './css/Home.css'



const Home = () => {

    /* posizione*/
    const [address, setAddress] = useState('')
    const [debouncedAdress, setDebouncedAdress] = useState('')
    const [googleMapsUrl, setGoogleMapsUrl] = useState('')
    const [osmUrl, setOsmUrl] = useState('')
    const [latitudine, setLatitudine] = useState('')
    const [longitudine, setLongitudine] = useState('')

    const [pulizie, setPulizie] = useState('')
    const [tipologia, setTipologia] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [resetData, setResetData] = useState(false)
    const [numero, setNumero] = useState('')


    const [gestori, setGestori] = useState([])
    const [bagniDisponibili, setBagniDisponibili] = useState([])

    const [modaleError, setModaleError] = useState(false)
    const [riepilogo, setRiepilogo] = useState()


    useEffect(() => {
        const handler = setTimeout(() => {
          setDebouncedAdress(address);
        }, 500); // 500ms di ritardo
    
        // Pulisce il timeout precedente se l'inputValue cambia prima che scada il tempo
        return () => {
          clearTimeout(handler);
        };
      }, [address]);


      const handleAdressChange = (e) => {
        setAddress(e.target.value);
      };

      async function getCoordinates(address) {
        try {
            const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                params: {
                    format: 'json',
                    q: address
                }
            });
            
            const data = response.data;
            if (data.length > 0) {
                console.log(`Latitudine: ${data[0].lat}, Longitudine: ${data[0].lon}`);
                setLatitudine(data[0].lat)
                setLongitudine(data[0].lon)
                setGoogleMapsUrl(`https://www.google.com/maps?q=${data[0].lat},${data[0].lon}&z=15&output=embed`);
                setOsmUrl(`https://www.openstreetmap.org/export/embed.html?bbox=${data[0].lon},${data[0].lat},${data[0].lon},${data[0].lat}&layer=mapnik&marker=${data[0].lat},${data[0].lon}`);
            } else {
                console.log('Nessun risultato trovato');
            }
        } catch (error) {
            console.error('Errore durante la richiesta:', error);
        }
    }

      useEffect(() => {
        if (debouncedAdress) {
            getCoordinates(debouncedAdress);
        }
        }, [debouncedAdress]);


        const handleChangePulizie = (e) => {
            setPulizie(e.target.value)
        }
        const hadleChangeTipologia = (e) => {
            setTipologia(e.target.value)
        }
        const getData = (startDate,endDate) => {
            if (startDate) {
                setStartDate(startDate)
            }
            if (endDate) {
                setEndDate(endDate)
            }
        }

        const getBagniNear = async () => {
            const distanza = 100;
            const option='bagni'
            const prezzo = 0;
            // erifico che i campi siano stati compilati
            if (!tipologia || !latitudine || !longitudine || !startDate || !endDate || !numero) {
                setModaleError('Compilare tutti i campi')
                return
            }
            try {
              const prodottiLiberi = await getBagniNearbyHome(option, tipologia, latitudine, longitudine, distanza, startDate, endDate, numero, prezzo);
              const tmpProdotti = [];

              let bagni_id = []
              let changeUtenId = false
              prodottiLiberi.forEach((element,index) => {
                // se cambia l'utente id, svuoto l'array
                // estraggo gli id dei bagni per utente e li pusho in un array
                element.bagni.forEach((bagno,index) => {
                    if(element.bagni.length-1 === index){
                        bagni_id.push(bagno._id)
                        changeUtenId = true
                        
                    }else{
                        bagni_id.push(bagno._id)
                        changeUtenId = false

                    }
                })
                if(changeUtenId){

                    /*
                utenteId={utente.id}
                bagniId={utente.bagniId}
                dataInizio={startDate}
                dataFine={endDate}
                tipologiaId={utente.tipologiaId}
                impiegoId={utente.impiegoId}
                    */
                tmpProdotti.push({
                    utenteId: element.utenteId,
                    value:  element.utenteId,
                    prezzo: element.prezzo,
                    label: element.utenteId,
                    username:element.bagni[0]?.utente?.username,
                    bagni_id:bagni_id,
                    stripeId:element.bagni[0]?.utente?.stripeId,
                    tipologiaId:element.bagni[0]?.tipologia._id,
                    impiego:tipologia 
                  });
                    bagni_id = []
                }
              });
              console.log(tmpProdotti)
              setBagniDisponibili(tmpProdotti);
            } catch (error) {
              /*setError(true);
              setErrorText(error.message);*/
              console.error(error);
              setModaleError(error)
              setBagniDisponibili([]);
            }
          };

    const closeModal = () => {

        setBagniDisponibili([])
        // azzero tutti i campi
        setAddress('')
        setDebouncedAdress('')
        setGoogleMapsUrl('')
        setOsmUrl('')
        setLatitudine('')
        setLongitudine('')
        setPulizie('')
        setTipologia('')
        setStartDate('')
        setEndDate('')
        setNumero('')
        setResetData(true)

    }
    const resetDataChage = () => {
        setResetData(false)
    }
    const returnRiepilogo = (utente) => {
        setRiepilogo(utente)
    }
    return (
        <div>
        {/*<h1 className="header-page">Home</h1>*/}
            <header class="hero-section">
                <h1>BagniPro</h1>
                <p className="hero-subtitle">Il gestionale ideale per ottimizzare i processi di pulizia, gestione delle consegne,</p>
                <p className="hero-subtitle">organizzazione delle aree e interazione con clienti e collaboratori.</p>
            </header>

            <section class="about-section">
                <h2>Benvenuto nel tuo partner di fiducia</h2>
                <p>
                    Che tu stia organizzando un grande evento, un festival all'aperto, o gestendo un cantiere, abbiamo tutto ciò che ti serve per garantire igiene, sicurezza e comfort.
                </p>
            </section>

            <section class="services-section">
                <h2>I nostri servizi:</h2>
                <ul>
                    <li><strong>Giri Pulizie:</strong>Pianifica e gestisci i giri di pulizia per ogni bagno chimico noleggiato.</li>
                    <li><strong>Consegne:</strong> Organizza la logistica delle consegne per un servizio efficiente.</li>
                    <li><strong>Creazione Aree:</strong> Definisci e configura le aree per facilitare la gestione delle unità.</li>
                    <li><strong>Area Clienti:</strong> Offri ai tuoi clienti uno spazio dedicato per monitorare i servizi.</li>
                    <li><strong>Area Collaboratori:</strong> Fornisci ai tuoi collaboratori strumenti per gestire al meglio le attività.</li>
                </ul>
            </section>

            <section class="why-choose-section">
                <h2>Perché sceglierci?</h2>
                <ul>
                    <li><strong>Qualità garantita:</strong>Il nostro gestionale è semplice da utilizzare ed offre il massimo comfort e affidabilità.</li>
                    <li><strong>Flessibilità e personalizzazione:</strong>Puoi gestire in pochi click tutto quello che riguarda la gestione del noleggio.</li>
                    <li><strong>Supporto clienti dedicato:</strong> Il nostro team è a tua disposizione per consigliarti la soluzione più adatta e assisterti in ogni fase del processo.</li>
                </ul>
            </section>



            <div class="call-to-action">
                <p>Organizzare eventi o gestire un cantiere non è mai stato così facile. 
                    Scopri le nostre offerte speciali e Richiedi la tua prova Gratuita!</p>
                <a href="#form-prenotazione" class="cta-button">Prenota ora!!</a>
            </div>
            <CardServizi />
            {/*modaleError ? <div className="modal">
                <div className="modal-content-home">
                    <span className="close" onClick={() => setModaleError(false)}>&times;</span>
                    <h2>Errore</h2>
                    <p>{modaleError}</p>
                </div>
            </div> : null*/}
            {/*bagniDisponibili.length > 0 ? <>
                <div className="modal">
                    <div className="modal-content-home">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>Prenota</h2>
                        {!riepilogo && bagniDisponibili.map((bagno) => 
                        <Card 
                            returnRiepilogo={returnRiepilogo} 
                            utente={bagno} 
                            closeModal={closeModal}
                        />)}
                        {riepilogo && <RiepilogoOrdine 
                        utente={riepilogo} 
                        closeModal={closeModal} 
                        startDate={startDate}
                        endDate={endDate}
                        numero={numero}
                        pulizie={pulizie}
                        />}
                    </div>
                </div>

            </>: null*/}
            {/*   <div className="form-container-search-bagni" id="form-prenotazione">
                <div class="prenotazione-spiegazione">
                    <h2>Prenota i tuoi bagni chimici in pochi minuti!</h2>
                    <p>Inserisci le tue necessità e ricevi subito più offerte personalizzate.</p>
                    <p>Prenota immediatamente, senza bisogno di lunghe richieste di preventivi o chiamate!</p>
                </div>

                <h2 className="h2-search-bagni">Prenota Bagni</h2>
                    <label className="label-search-bagni" htmlFor="search">seleziona data</label>
                    <Calendario getData={getData} resetData={resetData} resetDataChage={resetDataChage}/>
                    <label className="label-search-bagni" htmlFor="search">inserisci città</label>
                    <input
                        type="text"
                        value={address}
                        onChange={handleAdressChange}
                        placeholder="inserisci città, civico,via"
                    />
                    { googleMapsUrl ? 
                        <iframe
                        title="Google Maps"
                        src={osmUrl}
                        width="500"
                        height="250"
                        allowFullScreen=""
                        loading="lazy"
                        style={{ border: 0 }}
                        ></iframe> : null
                        }
                    <label className="label-search-bagni" htmlFor="search">inserisci il numero di bagni da noleggiare</label>
                    <input type="number" value={numero} min={1} placeholder="inserisci numero di bagni" onChange={(e)=>setNumero(e.target.value)}/>
                    <label className="label-search-bagni" htmlFor="search">seleziona tipologia</label>
                    <select className="select-search-bagni" value={tipologia} onChange={hadleChangeTipologia}>
                        <option value="">seleziona un opzione</option>
                        <option value="cantiere">Cantiere</option>
                        <option value="eventi">Evento</option>
                    </select>
                    <label className="label-search-bagni" htmlFor="search">vuoi associare un servizio pulizia</label>
                    <form className="form-check-radio" onChange={handleChangePulizie}>
                        <input className="radio-pulizie" type="radio" id="no" name="service" value="no" />
                        <label htmlFor="no">No</label>
                        
                        <input className="radio-pulizie" type="radio" id="yes1" name="service" value="1g" />
                        <label htmlFor="yes1">1 volta al giorno</label>
                        
                        <input className="radio-pulizie" type="radio" id="yes2" name="service" value="2g" />
                        <label htmlFor="yes2">2 volte al giorno</label>
                        
                        <input className="radio-pulizie" type="radio" id="yes3" name="service" value="3g" />
                        <label htmlFor="yes3">3 volte al giorno</label>
                        
                        <input className="radio-pulizie" type="radio" id="yes4" name="service" value="4g" />
                        <label htmlFor="yes4">4 volte al giorno</label>
                        
                        <input className="radio-pulizie" type="radio" id="yes5" name="service" value="1s" />
                        <label htmlFor="yes5">una volta ogni 5 giorni</label>

                        <input className="radio-pulizie" type="radio" id="yes6" name="service" value="2m" />
                        <label htmlFor="yes6">una volta ogni 15 giorni</label>
                    </form>

                    <button className="button-search-bagni" onClick={getBagniNear}>cerca bagni</button>
                </div>
                < FormCalcola />*/}
                <Footer />
        </div>
    );
    };

export default Home;