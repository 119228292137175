


export const CheckoutCancel = () => (
    <div>
        <h1>Errore durante il pagamento</h1>
        <p>Il pagamento è stato annullato. Riprova più tardi.</p>
    </div>
);

export default CheckoutCancel;
