import React, { useState,useEffect } from 'react';
import '../css/ModalAssociaConsegne.css';
import {getCollaboratori} from '../../../api/collaboratori/api'
const ModalAssociaConsegna = ({ isOpen, onClose, onSubmit }) => {
    const [checkedValues, setCheckedValues] = useState({
        consegna: false,
        ritiro: false
    });
    const [selectedOption, setSelectedOption] = useState('');
    const [collaboratori, setCollaboratori] = useState([]);
    const givMeCollaboratori = async () => {
        try {
          const response = await getCollaboratori();
          setCollaboratori(response);
        } catch (error) {
          console.error(error);
        }
      };


    useEffect(() => {
        if (collaboratori.length === 0) {
          givMeCollaboratori()
        }
      }, []);
    if (!isOpen) return null;

    const handleCheckboxChange = (e) => {
        setCheckedValues({
            ...checkedValues,
            [e.target.name]: e.target.checked
        });
    };

    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleSubmit = () => {
        onSubmit(checkedValues, selectedOption);
        onClose(); // Optionally close the modal after submission
    };

    
      



    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Associa Azioni</h2>
                <div className="modal-body">
                    <div className="checkbox-group">
                        <label>
                            <input
                                type="checkbox"
                                name="consegna"
                                checked={checkedValues.consegna}
                                onChange={handleCheckboxChange}
                            />
                            Consegna
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="ritiro"
                                checked={checkedValues.ritiro}
                                onChange={handleCheckboxChange}
                            />
                            Ritiro
                        </label>
                    </div>
                    <div className="select-group">
                        <label htmlFor="selectOption">Seleziona un'opzione:</label>
                        <select id="selectOption" value={selectedOption} onChange={handleSelectChange}>
                            <option value="">Seleziona un collaboratore</option>
                            {collaboratori.map((collaboratore) => (
                                <option key={collaboratore._id} value={collaboratore._id}>
                                    {collaboratore.nome}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="modal-button associa" onClick={handleSubmit}>Associa</button>
                    <button className="modal-button chiudi" onClick={onClose}>Chiudi</button>
                </div>
            </div>
        </div>
    );
};

export default ModalAssociaConsegna;
