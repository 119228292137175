import React from 'react';
import './css/mostratutti.css'; // Importa il tuo file CSS per lo stile

const MostraPrezzoPulizie = ({ dataBagni }) => {
  // Controllo se esistono punti da mostrare
  if (!dataBagni || dataBagni.length === 0) {
    return <p>No dataBagni to show</p>;
  }

  return (
    <div className="table-container">
      <table className="responsive-table">
        <thead>
          <tr>
            <th>Tipologia</th>
            <th>Impiego</th>
            <th>Costo</th>
          </tr>
        </thead>
        <tbody>
          {dataBagni.map((bagno, index) => (
            
            <tr key={index}>
              <td>{bagno?.tipologia?.nome}</td>
              <td>{bagno?.impiego?.impiego[0]}</td>
              <td>{
                bagno?.costo?.map((costo, index) => (
                    <tr key={index}>
                        <td> 
                        <p>{costo.periodicita}</p>
                        <p>{costo.prezzo}</p>  
                        </td>
                    </tr>
                ))
                }</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MostraPrezzoPulizie;
