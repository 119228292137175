import { getApi } from "../api";

export const getProdotti = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}prodotti/getTipoProdotti`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
    });
    const data = await response.json();
    return data;
}

export const getImpieghi = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}prodotti/getImpieghi`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
    });
    const data = await response.json();
    return data;
}



export const addUserProdotto = async (option,impiego,prezzo,latitudine,longitudine,disponibile,numero,city) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/createBagniUtente`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ impiego,prezzo,latitudine,longitudine,disponibile,tipologia: option,numero,city })
    });
    const data = await response.json();
    return data;
}

export const addUserProdottoMultipli = async (option,impiego,prezzo,latitudine,longitudine,disponibile,numero,quantita,city) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/createBagniUtenteMultipli`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ impiego,prezzo,latitudine,longitudine,disponibile,tipologia: option,numero,quantita,city })
    });
    const data = await response.json();
    return data;
}


export const getBagniNearby = async (tipologia, impiego,latitudine, longitudine, distanza, startPrenotazione, endPrenotazione,numero,prezzo) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/getBagniNearby`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ tipologia, impiego, latitudine, longitudine, distanza, startPrenotazione, endPrenotazione,numero })
    });
    const data = await response.json();
    return data;
};

export const getBagniNearbyHome = async (tipologia, impiego,latitudine, longitudine, distanza, startPrenotazione, endPrenotazione,numero,prezzo) => {
    const response = await fetch(`${getApi()}bagniUtente/getBagniNearbyHome`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tipologia, impiego, latitudine, longitudine, distanza, startPrenotazione, endPrenotazione,numero })
    });
    const data = await response.json();
    
    return data;
};

export const prenotaBagno = async (
    bagnoId, 
    inizioPrenotazione, 
    finePrenotazione,
    nomePrenotazione,
    longitudine,
    latitudine,
    puliziaIsActived,
    puliziaStart,
    puliziaEnd,
    puliziaPeriodicita,
    puliziaRicorrenza,
    stato,
    statoPagamento,
    tipologia,
    impiego,
    emailCliente
    ) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/prenotaBagno`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
             bagnoId,
             inizioPrenotazione, 
             finePrenotazione, 
             nomePrenotazione,
             longitudine,
             latitudine,
             puliziaIsActived,
             puliziaStart,
             puliziaEnd,
             puliziaPeriodicita,
             puliziaRicorrenza,
             stato,
             statoPagamento,
             tipologia,
             impiego,
             emailCliente
             })
    });
    const data = await response.json();
    return data;
}

export const giveMeAllBagniUtente = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/giveMeAllBagniUtente`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    });
    const data = await response.json();
    return data;
}


export const giveMeGiroPilizieUtente = async (startDate,endDate,time,distanza,area) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/giveMeGiroPilizieUtente`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({dataStart:startDate,dataEnd:endDate,time,distanza,area})
    });
    const data = await response.json();
    return data;
}

export const giveMeBagniPremootatiForDayAndUser = async (startDate,endDate,time,distanza,area) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/giveMeBagniPremootatiForDayAndUser`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({dataStart:startDate,dataEnd:endDate,time,distanza,area})
    });
    const data = await response.json();
    return data;
}


export const giveMeBagniPremootatiForDayAndUserCollaboratore = async (startDate,endDate,time,distanza,url) => {
    const token = localStorage.getItem('token-guest');
    const response = await fetch(`${getApi()}bagniUtente/giveMeBagniPremootatiForDayAndUserCollaboratori`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({dataStart:startDate,dataEnd:endDate,time,distanza,url})
    });
    const data = await response.json();
    return data;
}

export const giveMeBagniPulizieForDayAndUserCollaboratore = async (startDate,endDate,time,distanza,url,area) => {
    const token = localStorage.getItem('token-guest');
    const response = await fetch(`${getApi()}bagniUtente/giveMeBagniPulizieForDayAndUserCollaboratore`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({dataStart:startDate,dataEnd:endDate,time,distanza,url,area})
    });
    const data = await response.json();
    return data;
}


export const updateStatoConsegna = async (stato,id) => {
    const token = localStorage.getItem('token') || localStorage.getItem('token-guest');
    const response = await fetch(`${getApi()}bagniUtente/changeStatoBagnoPrenotato`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({id,stato})
    });
    const data = await response.json();
    return data;
}

/*            
            const obj = {
                prezzo: prezzo,
                impiegoId: impiegoId,
                tipologiaId: prodottoId,
                periodicita: periodicita,
            }
            */
//export const costoPulizia = async (tipologia,impiego,periodicita,prezzo) => {
export const costoPulizia = async (obj) => {
    /** const { tipologia, impiego, periodicita, prezzo } */
    const { tipologiaId, impiegoId, periodicita, prezzo } = obj;
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/costoPulizia`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ tipologia:tipologiaId, impiego:impiegoId, periodicita, prezzo })
    });
    const data = await response.json();
    return data;
}


export const getCostoPulizia = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/getCostoPulizia`, {
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    });
    const data = await response.json();
    return data;
}



export const giveMeAllCollaboratoreAssociatoConsegneRitiri = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/giveMeAllCollaboratoreAssociatoConsegneRitiri`, {
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    });
    const data = await response.json();
    return data;
}


export const createOrUpdateArea = async (area,nome) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/createOrUpdateArea`, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({area,nome})
    });
    const data = await response.json();
    return data;
}

export const getAreeUtente = async () => {

    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/getAreeUtente`, {
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    });
    const data = await response.json();
    return data;
}

export const deletArea = async (nome) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/deletArea`, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({nome})
    });
    const data = await response.json();
    return data;
}

export const raggruppaPrenotazioniAree = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/raggruppaPrenotazioniAree`, {
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    });
    const data = await response.json();
    return data;
}

export const changeKanbanPulizie = async (time, content,destinationColumn) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/changeKanbanPulizie`, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({time,content,destinationColumn})
    });
    const data = await response.json();
    return data;
}

export const deleteKanbanPulizie = async (time, content) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/deleteKanbanPulizie`, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({time,content})
    });
    const data = await response.json();
    return data;
}

export const updateStatoPulizia = async (id,stato) => {
    const token = localStorage.getItem('token') || localStorage.getItem('token-guest');
    const response = await fetch(`${getApi()}bagniUtente/updateStatoPulizia`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({id,stato})
    });
    const data = await response.json();
    return data;
}

export const uploadImagePulizia = async (formData, id_pulizie) => {
    try {
      const token = localStorage.getItem("token") || localStorage.getItem("token-guest");
  
      if (!token) {
        throw new Error("No token found in localStorage");
      }
  
      // Aggiungi l'id_pulizie a formData
      formData.append("id", id_pulizie);
  
      const response = await fetch(`${getApi()}bagniUtente/uploadImagePulizia`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: formData, // Solo formData viene inviato nel body
      });


  
      if (response.status != 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error in uploadImagePulizia:", error);
      throw error; // Rilancia l'errore per gestirlo nel componente
    }
  };
  
  export const aggiungiNotaPulizia  = async (id,nota) => {

    const token = localStorage.getItem('token') || localStorage.getItem('token-guest');
    const response = await fetch(`${getApi()}bagniUtente/aggiungiNotaPulizia`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({id,nota})
    });
    const data = await response.json();
    return data;
  }

  export const annullaOrdineFromId = async (id) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/annullaOrdineFromId`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({id})
    });
    const data = await response.json();
    return data;
  }
  
  
export const giveMeBagniRitiriForDayAndUser = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/giveMeBagniRitiriForDayAndUser`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    });
    const data = await response.json();
    return data;
}

export const giveMeBagniRitiriForDayAndCollaboratore = async (url) => {
    const token = localStorage.getItem('token-guest');
    const response = await fetch(`${getApi()}bagniUtente/giveMeBagniRitiriForDayAndCollaboratore`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({url})
    });
    const data = await response.json();
    return data;
}

export const creaRitiro = async (idOrdine,idMagazzino) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}bagniUtente/creaRitiro`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({idMagazzino, idOrdine})
    });
    const data = await response.json();
    return data;
}

export const creaRitiroCollaboratore = async (idOrdine,idMagazzino,url) => {
    const token = localStorage.getItem('token-guest');
    const response = await fetch(`${getApi()}bagniUtente/creaRitiroCollaboratore`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({idMagazzino, idOrdine,url})
    });
    const data = await response.json();
    return data;
}

