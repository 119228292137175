import { useState,useEffect } from 'react'

import axios from 'axios'
import { addUserProdotto, addUserProdottoMultipli } from '../../api/prodotti/api'
import Multiselect from 'multiselect-react-dropdown';
import './css/Aggiungi.css'
import { add } from 'date-fns';


const idCliente = 1

const Aggiungi = ({option}) => {
    const [posizione, setPosizione] = useState('')
    const [latitudine, setLatitudine] = useState('')
    const [longitudine, setLongitudine] = useState('')
    const [numero, setNumero] = useState('')
    const [idProdotto, setIdProdotto] = useState('')
    const [statoSelected, setStatoSelected] = useState('')
    const [prezzo, setPrezzo] = useState('')
    const [impiego, setImpiego] = useState('')

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [file, setFile] = useState(null);
    const [optionImpiego, setOptionImpiego] = useState({options: [{name: 'eventi', id: 1, prezzo:''},{name: 'cantiere', id: 2, prezzo:''}]})

    const [address, setAddress] = useState('')
    const [debouncedAdress, setDebouncedAdress] = useState('')
    const [googleMapsUrl, setGoogleMapsUrl] = useState('')
    const [city, setCity] = useState('')


    const aggiungi = async () => {
        let disponibile = statoSelected == 'si' ? true : false
        //const {tipologia,impiego,prezzo,posizione,numero,disponibile}
        if (numero > 1) {
            // se il numero è maggiore di 1 chiamo l'endo point aggiungi multipli 
            
            try {

                if (option == 'bagni') {
                    let prodottoAggiunto = await addUserProdottoMultipli(option,impiego,prezzo,latitudine,longitudine,disponibile,idProdotto,numero,city)
                    setLatitudine('')
                    setLongitudine('')
                    setNumero('')
                    setIdProdotto('')
                    setStatoSelected('')
                    setPrezzo('')
                    setImpiego('')
                    setOptionImpiego({options: [{name: 'eventi', id: 1, prezzo:''},{name: 'cantiere', id: 2, prezzo:''}]})
                    setCity('')
                    console.log(prodottoAggiunto)
                }
            }
            catch (error) {
                setError(true)
                setErrorText(error.message)
            }
            return
        }
        try {
            if(option == 'bagni') {
                let prodottoAggiunto = await addUserProdotto(option,impiego,prezzo,latitudine,longitudine,disponibile,idProdotto,city)
                setLatitudine('')
                setLongitudine('')
                setNumero('')
                setIdProdotto('')
                setStatoSelected('')
                setPrezzo('')
                setImpiego('')
                setOptionImpiego({options: [{name: 'eventi', id: 1, prezzo:''},{name: 'cantiere', id: 2, prezzo:''}]})
                setCity('')


                console.log(prodottoAggiunto)
            }
        } catch (error) {
            setError(true)
            setErrorText(error.message)
        }
    }


    // Funzione per gestire il caricamento del file
    const caricaCsv = (event) => {
        setFile(event.target.files[0]);
    };

  // Funzione per inviare il file al server
  const aggiungiAutoDaCsv = async () => {
    if (!file) {
      alert('Seleziona un file CSV prima di continuare');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('http://localhost:5000/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('File inviato con successo!');
      console.log('Risposta del server:', response.data);
    } catch (error) {
      console.error('Errore durante l\'invio del file:', error);
      alert('Errore durante l\'invio del file');
    }
  };


    const onSelect = (selectedList, selectedItem) => {
        setImpiego(selectedList)
        console.log(impiego)
    }

    const onRemove = (selectedList, removedItem) => {
        setImpiego(selectedList)
    }


    useEffect(() => {
        const handler = setTimeout(() => {
          setDebouncedAdress(address);
        }, 500); // 500ms di ritardo
    
        // Pulisce il timeout precedente se l'inputValue cambia prima che scada il tempo
        return () => {
          clearTimeout(handler);
        };
      }, [address]);


      const handleAdressChange = (e) => {
        setAddress(e.target.value);
      };

      async function getCoordinates(address) {
        try {
            const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                params: {
                    format: 'json',
                    q: address
                }
            });
            
            const data = response.data;
            if (data.length > 0) {
                console.log(`Latitudine: ${data[0].lat}, Longitudine: ${data[0].lon}`);
                setLatitudine(data[0].lat)
                setLongitudine(data[0].lon)
                setCity(data[0].name)
                setGoogleMapsUrl(`https://www.google.com/maps?q=${data[0].lat},${data[0].lon}&z=15&output=embed`);
            } else {
                console.log('Nessun risultato trovato');
            }
        } catch (error) {
            console.error('Errore durante la richiesta:', error);
        }
    }

      useEffect(() => {
        if (debouncedAdress) {
            getCoordinates(debouncedAdress);
        }
        }, [debouncedAdress]);

    return (
        <div className='aggiungi'>
            <p>Aggiungi {option} manualmente</p>
            <div className = 'container-aggiungi-servizio'>
                <label> inserisci la via con il formato: via, numero civico, città</label>
            <input
                    type="text"
                    value={address}
                    onChange={handleAdressChange}
                    placeholder="inserisci via, numero, citta..."
                />
                                { googleMapsUrl ? 
                <iframe
                  title="Google Maps"
                  src={googleMapsUrl}
                  width={window.innerWidth < 500 ? "300" : "500"}
                  height={window.innerWidth < 500 ? "250" : "500"}
                  allowFullScreen=""
                  loading="lazy"
                  style={{ border: 0 }}
                ></iframe> : null}
                <span>{debouncedAdress}</span>
                <input type="text" placeholder='latitudine' value={latitudine} onChange={(e) => setLatitudine(e.target.value)} />
                <input type="text" placeholder='longitudine' value={longitudine} onChange={(e) => setLongitudine(e.target.value)} />
                <input type='number' placeholder='numero' value={numero} onChange={(e) => setNumero(e.target.value)} />
                <select className='select-aggiungi-stato' value={statoSelected} onChange={(e) => setStatoSelected(e.target.value)}>
                    <option value=''>Seleziona lo stato</option>
                    <option value='si'>Disponibile</option>
                    <option value='no'>Non disponibile</option>
                </select>
                {/*<select className='select-aggiungi-impiego' value={impiego} onChange={(e) => setImpiego(e.target.value)}>
                    <option value=''>Seleziona l'impiego cantiere...</option>
                    <option value='eventi'>Eventi</option>
                    <option value='cantiere'>Cantiere</option>
                 </select>*/}
                <Multiselect
                    options={optionImpiego.options} // Options to display in the dropdown
                    selectedValues={impiego} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                />
                {impiego && impiego?.map((impieg,index) => {
                    return <input type="number" placeholder={`prezzo ${impieg.name}`} value={impieg.prezzo} onChange={(e) => {
                        let newArray = [...impiego]
                        newArray[index].prezzo = e.target.value
                        setImpiego(newArray)
                    }
                    } />

                }
                )}
                {/*<input type='number' placeholder='prezzo' value={prezzo} onChange={(e) => setPrezzo(e.target.value)} />*/}
                <button onClick={aggiungi}>Aggiungi</button>
            </div>
            <div className='aggiungi-auto-da-csv'>
                <p>Aggiungi {option} da csv</p>
                <input type='file' onChange={caricaCsv} accept=".csv" />
                <button onClick={aggiungiAutoDaCsv}>Aggiungi</button>
            </div>
        </div>
    )
}

export default Aggiungi