import { getApi } from '../api';


export const getPuliziaFromOrdine = async (id) => {
    try {
        const response = await fetch(`${getApi()}client/getPuliziaFromOrdine/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Errore nella richiesta: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error("Errore nella funzione getPuliziaFromOrdine:", error);
        return null;
    }
};



