import { useState,useEffect } from "react"
import { useSearchParams } from 'react-router-dom';
import Pulizie from "./Pulizie"
import {getPuliziaFromOrdine} from "../../api/client/api"

import './css/client.css'
const Client = () => {
    const [searchParams] = useSearchParams();

    const paramValue = searchParams.get('param');

    const [pulizia, setPulizia] = useState([]);

    const giveMePulizie = async () => {
        const response = await getPuliziaFromOrdine(paramValue);
        console.log(response);
        setPulizia([...response]);
    }

    useEffect(() => {
        giveMePulizie();
    }, [])



    return (
        <div className="client-main">
            <Pulizie pulizia={pulizia}/>
        </div>
    )
}

export default Client