
import { useState,useEffect } from 'react';

import { loginCollaboratore,tokenExpired } from '../../api/collaboratori/api';

import { useNavigate } from 'react-router-dom';

import './css/login.css'


const LoginGuest = ({param,checkLogin}) => {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const isExpiredToken = async () => {
        //const response = await tokenExpired(localStorage.getItem('token-guest'));
        //const data = await response.json();
        console.log('Token valido');
        navigate(`/giro_consegne_guest?param=${encodeURIComponent(param)}`)

    }

    useEffect(() => {
        if(localStorage.getItem('token-guest')){
            isExpiredToken() 
        }
    }, [])


    const logInGuest = async (e) => {
        e.preventDefault();
        try {
            const response = await loginCollaboratore(password,param);
            console.log(response);
            localStorage.setItem('token-guest', response.token);
            if (response.error) {
                alert(response.error);
            } else {
                localStorage.setItem('token-guest', response.token);
                //giro_consegne_guest?param=08hwy9gicx1b92ei6yxyljt66e56880fa5454cb90befc71
                navigate(`/giro_consegne_guest?param=${encodeURIComponent(param)}`);
                checkLogin();
                alert('Login effettuato con successo');
            }
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <div className="container-collaboratori-login">
            <h2>Accedi</h2>
            <form>
                <div className="form-group-collaboratori-login">
                    <label htmlFor="password">Password</label>
                    <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <button type="submit" className="btn btn-primary" onClick={logInGuest}>Accedi</button>
            </form>
        </div>
    );
}

export default LoginGuest;