import { useState, useEffect,useCallback } from 'react';
import { giveMeBagniPremootatiForDayAndUserCollaboratore,giveMeBagniRitiriForDayAndCollaboratore,creaRitiroCollaboratore } from '../../api/prodotti/api';
import MostraTutti from '../map/MostraTutti';
import MostraTabella from '../gestore/tabelle/MostraPrenotazioni'; // Correzione del nome dell'import
import { useSearchParams } from 'react-router-dom';
import LoginGuest from './LoginGuest';
import ModalRitiri from '../gestore/modali/ModalRitiro';
import Calendario from '../gestore/Calendario'; 

import '../gestore/css/Prenotazioni.css';
import './css/GiroConsegneGuest.css';

const statoConsegna = ['daconsegnare', 'Consegnato'];
const option = 'bagni';
const GiroConsegneGuest = () => {
  const [data, setData] = useState([]);
  const [dataRitiri, setDataRitiri] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [mostraMappa, setMostraMappa] = useState(false);
  const [mostraTabella, setMostraTabella] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [isLogin, setIsLogin] = useState(false);

  const [searchParams] = useSearchParams();
    
  // Ottieni il valore del parametro "param" dalla query string
  const paramValue = searchParams.get('param');

  const [isConsegne, setIsConsegne] = useState(true);


  const [modalRitiri, setModalRitiri] = useState(false);
  const [daRitirare, setDaRitirare] = useState([]);


  useEffect(() => {
    if (localStorage.getItem('token-guest')) {
      setIsLogin(true);
    }
  }, []);


  const checkLogin = () => {
    setIsLogin(true);
  };
  const handleToggle = () => {
    setData([]);
    setIsConsegne(!isConsegne);
};
 
  const getAllBagniUtente = async (time,distanza) => {
    // prondo l'ultima parte del url dopo giro_consegne_guest
    //devo estrarre il param giro_consegne_guest&param=08hwy9gicx1b92ei6yxyljt66e56880fa5454cb90befc71

    if (option == 'bagni'){
        try {
        const response = await giveMeBagniPremootatiForDayAndUserCollaboratore(startDate, endDate,time,distanza,paramValue);
        const responseRitiri = await giveMeBagniRitiriForDayAndCollaboratore(paramValue);
        console.log(response);
        if(response.message == 'Token expired'){
          localStorage.removeItem('token-guest');
          setIsLogin(false);
          return;
      }

        let dataTmap = [];
        let dataTmapRitiri = [];
        response.forEach((element) => {
          let empty = {_id:'',nome:''};
          dataTmap.push({
            lat: element.posizione?.coordinates[1],
            lng: element.posizione?.coordinates[0],
            info: element.bagnoId?.numero,
            start: isConsegne ? (element.inizioPrenotazione || '') : (element.finePrenotazione || ''),
            end: isConsegne ? (element.finePrenotazione || '') : (''|| ''),
            statoPagamento: element.statoPagamento ? element.statoPagamento[0] : '',
            statoConsegna: element.stato ? element.stato[0] : '',
            cliente: element.nomePrenotazione,
            ordineId: element.ordineId?._id || '',
            area:element.ordineId?.area,
            consegne: empty, //consegne.length ? {nome:consegne[0]?.idCollaboratore?.nome, id:consegne[0]._id} : {nome:empty.nome,id:empty._id},
            ritiri: empty //ritiri.length ? {nome:ritiri[0]?.idCollaboratore?.nome, id:ritiri[0]._id} : {nome:empty.nome,id:empty._id},
        });
        });
        // rimuovo da dataTmap i bagni che non hanno stato Non consegnato
        responseRitiri.forEach((element) => {
          let empty = {_id:'',nome:'Non assegnato'};
          dataTmapRitiri.push({
            lat: element.posizione?.coordinates[1],
            lng: element.posizione?.coordinates[0],
            info: element.bagnoId?.numero,
            start: isConsegne ? (element.inizioPrenotazione || '') : (element.finePrenotazione || ''),
            end: isConsegne ? (element.finePrenotazione || '') : (''|| ''),
            statoPagamento: element.statoPagamento ? element.statoPagamento[0] : '',
            statoConsegna: element.stato ? element.stato[0] : '',
            cliente: element.nomePrenotazione,
            ordineId: element.ordineId?._id || '',
            area:element.ordineId?.area,
            consegne: empty, //consegne.length ? {nome:consegne[0]?.idCollaboratore?.nome, id:consegne[0]._id} : {nome:empty.nome,id:empty._id},
            ritiri: empty //ritiri.length ? {nome:ritiri[0]?.idCollaboratore?.nome, id:ritiri[0]._id} : {nome:empty.nome,id:empty._id}
          });
      });



        setData(dataTmap);
        setDataRitiri(dataTmapRitiri);

        setLoading(false);
        setMostraTabella(true);
        } catch (error) {
        setError(true);
        setErrorText(error.message);
        setLoading(false);
        }
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      getAllBagniUtente();
    }
  }, [option, startDate, endDate, isConsegne]);


  const getData = (startDate,endDate) => {
    if (startDate) {
        setStartDate(startDate)
    }
    if (endDate) {
        setEndDate(endDate)
    }
}

const recalculate = (time,distanza) => {
  getAllBagniUtente(time,distanza);
}


const apriModalRitiri = (data,e) => {
  console.log(data,e);
  let newdata = data.filter(item => item.ordineId == e);
  setDaRitirare(newdata);
  setModalRitiri(true);
}

const ritiraOrdine = async(data) => {
  const ordineId = daRitirare.map(item => item.ordineId);
  // ripulisco l'array da eventuali duplicati
  const uniqOrdiniId = [...new Set(ordineId)];
  console.log(uniqOrdiniId,'ritiri');
  //
  let resp = await creaRitiroCollaboratore(uniqOrdiniId,data,paramValue);
  setModalRitiri(false);
  // in data c'è l'id del magazzino
  console.log(resp)
}



  return (
    <div className='prenotazioni'>
      < ModalRitiri  isOpen={modalRitiri} onClose={() => setModalRitiri(false)} onSubmit={ritiraOrdine}/>
      <div className="switch-container">
          <span className={`switch-label ${isConsegne ? 'active' : ''}`}><h2>Consegne</h2></span>
          <div className="switch" onClick={handleToggle}>
              <div className={`switch-button ${isConsegne ? 'left' : 'right'}`}></div>
          </div>
          <span className={`switch-label ${!isConsegne ? 'active' : ''}`}><h2>Ritiri</h2></span>
      </div>
      <div>
          {isConsegne ? <p>Visualizza dati di Consegne</p> : <p>Visualizza dati di Ritiri</p>}
      </div>
      {!isLogin && <LoginGuest param={paramValue} checkLogin={checkLogin}/>}
      {isLogin && <>
      <Calendario getData={getData} />
      {loading && <p>Caricamento...</p>}
      {error && <p>Si è verificato un errore: {errorText}</p>}
      {!loading && !error && (
        <div>
          {data.length === 0 && <p>Nessun bagno trovato</p>}
          {data.length > 0 && (
            <div>
              <p>Trovati  bagni</p>
              <div className='cont-button-bagni-prenotazioni'>
                {/* Corretto l'uso delle funzioni di stato per mostrare mappa e tabella */}
                <button
                  onClick={() => {
                    setMostraMappa(!mostraMappa);
                    if (mostraTabella) {
                      setMostraTabella(false);
                    }
                  }}
                >
                  Mostra sulla mappa
                </button>
                <button
                  onClick={() => {
                    setMostraTabella(!mostraTabella);
                    if (mostraMappa) {
                      setMostraMappa(false);
                    }
                  }}
                >
                  Mostra tabella
                </button>
              </div>
              {mostraMappa && <MostraTutti points={data} />}
              {mostraTabella && 
              <MostraTabella points={!isConsegne ? dataRitiri:  data}
              isRitiri={!isConsegne}
              apriModalRitiri={apriModalRitiri} 
               recalculate={recalculate}
               />}
            </div>
          )}
        </div>
        
      )}
      </>}
    </div>
  );
};

export default GiroConsegneGuest;
