import React, { useState,useEffect } from 'react';
import '../css/ModalAssociaConsegne.css';

const ModalAnnullaOrdine = ({ isOpen, onClose, onSubmit }) => {

    if (!isOpen) return null;

    const handleSubmit = () => {
        onSubmit(true);
        onClose(); // Optionally close the modal after submission
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Associa Azioni</h2>
                <div className="modal-body  modal-content">
                    Annullando questo ordine, verranno annullate tutte le pulizie programmate per questo ordine. Sei sicuro di voler procedere?
                </div>
                <div className="modal-footer">
                    <button className="modal-button associa" onClick={handleSubmit}>Conferma</button>
                    <button className="modal-button chiudi" onClick={onClose}>Chiudi</button>
                </div>
            </div>
        </div>
    );
};

export default ModalAnnullaOrdine;
