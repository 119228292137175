import  { useState, useEffect } from 'react'



const Andamento = ({option}) => {
        
            const [data, setData] = useState([])
            const [loading, setLoading] = useState(true)
            const [error, setError] = useState(false)
            const [errorText, setErrorText] = useState('')
        
            useEffect(() => {
                /*const fetchData = async () => {
                    try {
                        const response = await fetch(`http://localhost:3001/${option}/andamento`)
                        const data = await response.json()
                        setData(data)
                        setLoading(false)
                    } catch (error) {
                        setError(true)
                        setErrorText(error.message)
                        setLoading(false)
                    }
                }*/
        
                const fetchData =  () => {
                    setData(50)
                }
                fetchData()
            }, [option])
        
            return (
                <div className='andamento'>
                    <h2>Andamento</h2>
                    <p>hai {option} {data} andamento</p>
                </div>
            )
        }

export default Andamento