import  { useState, useEffect } from 'react'



const Noleggiati = ({option}) => {
    
        const [data, setData] = useState([])
        const [loading, setLoading] = useState(true)
        const [error, setError] = useState(false)
        const [errorText, setErrorText] = useState('')
    
        useEffect(() => {
            /*const fetchData = async () => {
                try {
                    const response = await fetch(`http://localhost:3001/${option}/noleggiati`)
                    const data = await response.json()
                    setData(data)
                    setLoading(false)
                } catch (error) {
                    setError(true)
                    setErrorText(error.message)
                    setLoading(false)
                }
            }*/
    
            const fetchData =  () => {
                setData(50)
            }
            fetchData()
        }, [option])
    
        return (
            <div className='noleggiati'>
                <h2>Noleggiati</h2>
                <p>hai {option} {data} noleggiati</p>
            </div>
        )
    }

export default Noleggiati