import React, { useState,useEffect } from 'react';
import '../css/ModalAssociaConsegne.css';
import {getMagazzino} from '../../../api/magazzino/api'
import { useSearchParams } from 'react-router-dom';


const ModalRitiri = ({ isOpen, onClose, onSubmit }) => {
    const [checkedValues, setCheckedValues] = useState({
        consegna: false,
        ritiro: false
    });
    const [selectedOption, setSelectedOption] = useState('');
    const [magazzini, setMagazzini] = useState([]);
    const [searchParams] = useSearchParams();
    const paramValue = searchParams.get('param');

    const giveMeMagazzini = async () => {
        try {
          const response = await getMagazzino(paramValue);
          setMagazzini(response);
        } catch (error) {
          console.error(error);
        }
      };


    useEffect(() => {
        if (magazzini.length === 0) {
            giveMeMagazzini()
        }
      }, []);


    if (!isOpen) return null;



    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleSubmit = () => {
        onSubmit(selectedOption);
        onClose(); // Optionally close the modal after submission
    };

    
      



    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Ritira</h2>
                <div className="modal-body">
                    <div className="select-group">
                        <label htmlFor="selectOption">Seleziona un'opzione:</label>
                        <select id="selectOption" value={selectedOption} onChange={handleSelectChange}>
                            <option value="">Seleziona un magazzino</option>
                            {magazzini.map((magazzino) => (
                                <option key={magazzino._id} value={magazzino._id}>
                                    {magazzino.nome}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="modal-button associa" onClick={handleSubmit}>Conferma Ritiro</button>
                    <button className="modal-button chiudi" onClick={onClose}>Chiudi</button>
                </div>
            </div>
        </div>
    );
};

export default ModalRitiri;
