import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import jsPDF from 'jspdf';

const SignatureForm = () => {
  const [isSigned, setIsSigned] = useState(false);
  const sigCanvas = useRef(null);

  // Pulire il canvas della firma
  const clearSignature = () => {
    sigCanvas.current.clear();
    setIsSigned(false);
  };

  // Gestione della firma
  const handleSignatureEnd = () => {
    if (!sigCanvas.current.isEmpty()) {
      setIsSigned(true);
    }
  };

  // Generare PDF con il testo e la firma
  const generatePdf = async () => {
    const pdf = new jsPDF();

    // Testo del documento
    const text = `
      Documento di preventivo
      ----------------------------
      Questo documento rappresenta il preventivo dell'offerta.
      Alla fine del documento è richiesta la firma del cliente.
    `;

    pdf.text(text, 10, 10);

    // Recuperare l'immagine della firma come base64
    const signatureImage = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    pdf.addImage(signatureImage, 'PNG', 10, 60, 180, 40);

    // Inviare il PDF al backend
    const pdfBlob = pdf.output('blob');
    const formData = new FormData();
    formData.append('file', pdfBlob, 'preventivo_firmato.pdf');

    try {
      await fetch('http://localhost:5000/upload-pdf', {
        method: 'POST',
        body: formData,
      });
      alert('Preventivo firmato inviato con successo!');
    } catch (error) {
      console.error('Errore durante l\'invio del preventivo:', error);
      alert('Errore durante l\'invio del preventivo.');
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '400px', margin: '0 auto' }}>
      <h3>Preventivo</h3>
      <p>
        Questo è il testo del preventivo. Firmare il documento qui sotto per accettare i termini e inviare il preventivo.
      </p>
      <div style={{ border: '1px solid #000', marginBottom: '10px' }}>
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          onEnd={handleSignatureEnd}
          canvasProps={{
            width: 350,
            height: 150,
            className: 'sigCanvas',
            style: { touchAction: 'none' },
          }}
        />
      </div>
      <button onClick={clearSignature} disabled={!isSigned} style={{ marginRight: '10px' }}>
        Cancella Firma
      </button>
      <button onClick={generatePdf} disabled={!isSigned}>
        Invia Preventivo Firmato
      </button>
    </div>
  );
};

export default SignatureForm;
