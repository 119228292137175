import { useState, useEffect,useCallback } from 'react';
import { giveMeBagniPulizieForDayAndUserCollaboratore } from '../../api/prodotti/api';
import MostraTutti from '../map/MostraTutti';
import MostraTabella from '../gestore/tabelle/MostraGiro'; // Correzione del nome dell'import
import Calendario from '../gestore/Calendario';
import LoginGuest from './LoginGuestPulizie';
import { useSearchParams } from 'react-router-dom';
import '../gestore/css/GiroPulizie.css';
const option = 'bagni';

const GiroPulizieGuest = () => {


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [mostraMappa, setMostraMappa] = useState(false);
    const [mostraTabella, setMostraTabella] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
  
    const [isLogin, setIsLogin] = useState(false);

    const [searchParams] = useSearchParams();
        
    // Ottieni il valore del parametro "param" dalla query string
    const paramValue = searchParams.get('param');
  

  
    useEffect(() => {
        if (localStorage.getItem('token-guest')) {
          setIsLogin(true);
        }
      }, []);
    
    
      const checkLogin = () => {
        setIsLogin(true);
      };
    const getAllBagniUtente = async (time,distanza,area) => {
      if (option == 'bagni'){
          try {
          const response = await giveMeBagniPulizieForDayAndUserCollaboratore(startDate, endDate,time,distanza,paramValue,area);
          console.log(response);
          let dataTmap = [];
          response.forEach((element) => {
              dataTmap.push({
              lat: element.posizione.coordinates[1],
              lng: element.posizione.coordinates[0],
              info: element.bagnoId?.numero,
              ora: element.oraPulizia,
              stato: !element.isPulito ? 'no' : 'si',
              ordineId: element.ordineId || '',
              cliente: element.prenotazioneId?.nomePrenotazione,
              collaboratoreId: element.collaboratoreId?.nome,
              pulizia_id: element._id,
              area:element.area || element.ordineId?.area,
              nota:element.nota,
              });
          });
  
          setData(dataTmap);
          setLoading(false);
          setMostraTabella(true);
          } catch (error) {
          setError(true);
          setErrorText(error.message);
          setLoading(false);
          }
      }
    };
  
    useEffect(() => {
      if (startDate && endDate) {
        getAllBagniUtente();
      }
    }, [option, startDate, endDate]);

    const updateData = () => {
      getAllBagniUtente();
    }
  
  
    const getData = (startDate,endDate) => {
      if (startDate) {
          setStartDate(startDate)
      }
      if (endDate) {
          setEndDate(endDate)
      }
  }
  
    const recalculate = (time,distanza,area) => {
      getAllBagniUtente(time,distanza,area);
    }
  

  
    return (
      <div className='giro'>
        <h2>Giro pulizie</h2>
        {!isLogin && <LoginGuest param={paramValue} checkLogin={checkLogin}/>}
        {isLogin && <>
        <Calendario getData={getData} />
        {loading && <p>Caricamento...</p>}
        {error && <p>Si è verificato un errore: {errorText}</p>}
        {!loading && !error && (
          <div>
            {data.length === 0 && <p>Nessun bagno trovato</p>}
            {data.length > 0 && (
              <div>
                <p>Trovati  bagni</p>
                <div className='cont-button-bagni-giro'>
                  {/* Corretto l'uso delle funzioni di stato per mostrare mappa e tabella */}
                  <button
                    onClick={() => {
                      setMostraMappa(!mostraMappa);
                      if (mostraTabella) {
                        setMostraTabella(false);
                      }
                    }}
                  >
                    Mostra sulla mappa
                  </button>
                  <button
                    onClick={() => {
                      setMostraTabella(!mostraTabella);
                      if (mostraMappa) {
                        setMostraMappa(false);
                      }
                    }}
                  >
                    Mostra tabella
                  </button>
                </div>
                {mostraMappa && <MostraTutti points={data} />}
                {mostraTabella && <MostraTabella 
                points={data} 
                recalculate={recalculate}
                updateData={updateData}
                />}
              </div>
            )}
          </div>
        )}
        </>}
      </div>
    );
    };

export default GiroPulizieGuest;