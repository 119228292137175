import { getApi } from "../api";


export const createMagazzino = async (nome,address,latitudine,longitudine) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}magazzino/createMagazzino`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ nome,address,latitudine,longitudine })
    });
    const data = await response.json();
    return data;
    
}

export const getMagazzino = async (param) => {
    const token = localStorage.getItem('token') || localStorage.getItem('token-guest');
    const response = await fetch(`${getApi()}magazzino/getMagazzino/${param}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
    });
    const data = await response.json();
    return data;
}
export const deleteMagazzino = async (id) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${getApi()}magazzino/deleteMagazzino`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id })
    });
    const data = await response.json();
    return data;
}