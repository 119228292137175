// Inizia con l'importazione dei Service Workers, se disponibili
// Questo file è basato sul modello di Service Worker fornito da Create React App

// URL del file service-worker.js
const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] è l'indirizzo IPv6 localhost
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 è considerato localhost per IPv4
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
  
  export function register(config) {
    if ('serviceWorker' in navigator) {
      // L'URL è stato usato perché i browser normalmente fanno caching dei file stessi
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
      if (publicUrl.origin !== window.location.origin) {
        // Condizione per lo stesso dominio
        return;
      }
  
      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
        if (isLocalhost) {
          // Questa parte gestisce l'ambiente di sviluppo localhost
          checkValidServiceWorker(swUrl, config);
  
          navigator.serviceWorker.ready.then(() => {
            console.log(
              'Questo sito è gestito tramite un Service Worker in modalità di sviluppo.'
            );
          });
        } else {
          // Registrazione del Service Worker per produzione
          registerValidSW(swUrl, config);
        }
      });
    }
  }
  
  function registerValidSW(swUrl, config) {
    navigator.serviceWorker
      .register(swUrl)
      .then(registration => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                console.log(
                  'Nuovo contenuto è disponibile; aggiorna la pagina per visualizzare.'
                );
  
                if (config && config.onUpdate) {
                  config.onUpdate(registration);
                }
              } else {
                console.log('Il contenuto è stato memorizzato per l’uso offline.');
  
                if (config && config.onSuccess) {
                  config.onSuccess(registration);
                }
              }
            }
          };
        };
      })
      .catch(error => {
        console.error('Errore durante la registrazione del Service Worker:', error);
      });
  }
  
  function checkValidServiceWorker(swUrl, config) {
    fetch(swUrl, {
      headers: { 'Service-Worker': 'script' }
    })
      .then(response => {
        // Verifica se il Service Worker esiste e se ha lo stato 200
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          // Unregister il Service Worker se non trovato
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          registerValidSW(swUrl, config);
        }
      })
      .catch(() => {
        console.log(
          'Nessuna connessione Internet trovata. L’applicazione è in esecuzione in modalità offline.'
        );
      });
  }
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then(registration => {
          registration.unregister();
        })
        .catch(error => {
          console.error('Errore durante la disinstallazione del Service Worker:', error);
        });
    }
  }
  