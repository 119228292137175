import  { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OnboardingButton from '../stripe/OnboardingButton';
import {getUser} from '../../api/user/api';
import './css/Profilo.css';
const Profilo = () => {


    /*
        username: String,
        stripeId: String,
        email: String,
        password: String,
        partitaIva: String,
        codiceFiscale: String,
        userRuolo: mongoose.SchemaTypes.ObjectId,
    */
    const [user, setUser] = useState({
        username: '',
        partitaIva: '',
        ragioneSociale: '',
        indirizzo: '',
        telefono: '',
        stripeId: '',
        email:'',
        codiceFiscale:'',
        city: '',
        postal_code: '',
        country: '',
        day: '',
        month: '',
        year: ''


    })

    const getDataUser = async () => {
        const response = await getUser()
        console.log(response)
        // elimino dalla risposta la password
        delete response.password

        setUser(response)
    }

    useEffect(() => {
        getDataUser()
    }, [])

    const [modifica, setModifica] = useState(false)

    const navigate = useNavigate();

    const handleGoToDashboard = (goTo) => {

        if(goTo){
            navigate(goTo)
        }

    }

    const onBoarding = () => {
        console.log('onboarding')
    }


    const registraProfilo = (e) => {
        e.preventDefault()
        console.log('registraProfilo')
        console.log(user)
        setModifica(false)
    }

    return (
        <div>
            {user ? <>
            {(!modifica && user.stripeId) &&
            <div className='info-container'>
                <div className='visualizzazione-profilo'>
                    <h2 style={{color:'white'}}>Benvenuto {user.username}</h2>
                    <p>Partita iva: {user.partitaIva}</p>
                    <p>Ragione sociale: {user.ragioneSociale}</p>
                    <p>Indirizzo: {user.indirizzo}</p>
                    <p>Telefono: {user.telefono}</p>
                    <p>Conto stripe: {user.stripeId}</p>
                    <p>Email: {user.email}</p>
                     <button className='button-generic' onClick={() => setModifica(true)}>Modifica</button>
                </div>
            </div>
            }
            {(modifica || !user.stripeId) &&
            <div className='info-container'>
                <div className="profilo-form-container">
                    <h2>Modifica il tuo profilo</h2>
                    <form className='form-profilo'>
                        <input type="text" placeholder="partita iva" value={user.partitaIva} onChange={(e)=> setUser({...user,partitaIva:e.target.value})} />
                        <input type="text" placeholder="ragione sociale" value={user.ragioneSociale} onChange={(e)=> setUser({...user,ragioneSociale:e.target.value})} />
                        <input type="text" placeholder="indirizzo" value={user.indirizzo} onChange={(e)=> setUser({...user,indirizzo:e.target.value})}/>
                        <input type="text" placeholder="telefono" value={user.telefono} onChange={(e)=> setUser({...user,telefono:e.target.value})} />
                        <input type="text" placeholder="id stripe verrà generato automaticamente" value={user.stripeId} disabled/>
                        <input type="text" placeholder="email"  value={user.email} disabled/>
                        <input type="text" placeholder="codice fiscale" value={user.codiceFiscale} onChange={(e)=> setUser({...user,codiceFiscale:e.target.value})} />
                        <input type="text" placeholder="username" value={user.username} onChange={(e)=> setUser({...user,username:e.target.value})} />
                        <input type="text" placeholder="città" value={user.city} onChange={(e)=> setUser({...user,city:e.target.value})} />
                        <input type="text" placeholder="cap" value={user.postal_code} onChange={(e)=> setUser({...user,postal_code:e.target.value})} />
                        <select name="country" id="country" value={user.country} onChange={(e)=> setUser({...user,country:e.target.value})}>
                            <option value="IT">Italia</option>
                            <option value="FR">Francia</option>
                            <option value="DE">Germania</option>
                            <option value="ES">Spagna</option>
                        </select>
                        <input type="text" placeholder="giorno di nascita" value={user.day} onChange={(e)=> setUser({...user,day:e.target.value})} />
                        <input type="text" placeholder="mese di nascita" value={user.month} onChange={(e)=> setUser({...user,month:e.target.value})} />
                        <input type="text" placeholder="anno di nascita" value={user.year} onChange={(e)=> setUser({...user,year:e.target.value})} />
                        {user.stripeId && <button className='button-generic' onClick={registraProfilo}>Salva</button> }
                        {!user.stripeId && <OnboardingButton user={user} />}
                    </form>
                    <button className='go-back-btn' onClick={()=>setModifica(false)}>esci senza salvare</button>
            </div>
            </div>
            }
            </> : 
            <div className="profilo-form-container">
            <h2>Registra la tua attività e inizia a vendere i tuoi servizi</h2>
            <form>
                <input type="text" placeholder="partita iva" />
                <input type="text" placeholder="ragione sociale" />
                <input type="text" placeholder="indirizzo" />
                <input type="text" placeholder="telefono" />
                <input type="text" placeholder="contostripe" />
                <div>non hai un conto stripe, clicca qui e creane uno gratuitamente 
                    <span onClick={onBoarding}> onbording</span>
                </div>
                <button className='button-generic' onClick={registraProfilo}>Registra</button>
            </form>
            </div>
            }
        </div>
    )
}

export default Profilo;