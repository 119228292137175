import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import './css/mostraPrenotazioni.css';

import {updateStatoConsegna} from '../../../api/prodotti/api';


const MostraGiro = ({ points,recalculate,apriModalAssociaConsegna,apriModalAnnullaOrdine,isRitiri,apriModalRitiri }) => {
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [newPoints, setPoints] = useState([]);


  const [raggruppaPerKm, setRaggruppaPerKm] = useState('');
  const [debouncedRaggruppaPerKm, setDebouncedRaggruppaPerKm] = useState(raggruppaPerKm);

  const [searchParams] = useSearchParams();


  const [filters, setFilters] = useState({
    ordineId: '',
    info: '',
    cliente: '',
    city: '',
    area: '',
    start: '',
    end: '',
    statoPagamento: '',
    statoConsegna: '',
  });
  useEffect(() => {
    setPoints(points);
  }, [points]);


    // Funzione per aggiornare i filtri
    const handleFilterChange = (field, value) => {
      setFilters({ ...filters, [field]: value });
    };
  
    // Funzione per filtrare i dati
    const getFilteredPoints = () => {
      return newPoints.filter((group) =>
        Array.isArray(group) &&
        group.some((point) =>
          Object.keys(filters).every((key) =>
            filters[key]
              ? String(point[key] || '').includes(filters[key])
              : true
          )
        )
      );
    };
  
    const filteredPoints = getFilteredPoints();
  
    
  // Ottieni il valore del parametro "param" dalla query string
  const paramValue = searchParams.get('param');
  const getCity = async (lat, lon) => {
    try {
      const response = await axios.get(
        `http://api.openweathermap.org/geo/1.0/reverse?lat=${lat}&lon=${lon}&limit=1&appid=${process.env.REACT_APP_OPENWEATHERMAP_API_KEY}`
      );
      return response.data[0]?.name || 'Unknown location';
    } catch (error) {
      setError(true);
      setErrorText(error.message);
      return 'Unknown location';
    }
  };

  const raggruppaPerOrdineId = (points) => {
    return points.reduce((acc, point) => {
      if (!point.lat) {
        acc.push({ isDelimiter: true }); // Aggiungo una riga vuota
      } else {
        const lastGroup = acc[acc.length - 1];
        if (lastGroup && Array.isArray(lastGroup) && lastGroup[0].ordineId?._id === point.ordineId?._id) {
          lastGroup.push(point); // Aggiungo al gruppo esistente
        } else {
          acc.push([point]); // Crea un nuovo gruppo
        }
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    if (points?.length) {
      const getCityNames = async () => {
        const newPointsTmp = [];
        let ponintPercorso = [];
        let pointPosizioneIniziale = [];

        for (const point of points) {
          const city = await getCity(point.lat, point.lng);
          let startPrenotazione = new Date(point.start).toLocaleDateString();
          let endPrenotazione = new Date(point.end).toLocaleDateString();

          if (point.posizione) {
            let posizione = point.posizione //.reverse();
            pointPosizioneIniziale.push([posizione[1], posizione[0]]);
            //ponintPercorso.push([...posizione,...ponintPercorso]);
          }
          if (point.lat) {
            ponintPercorso.push([point.lat, point.lng]);
          } else {
            if (ponintPercorso.length > 0) {
              ponintPercorso = [];
            }
          }

          newPointsTmp.push({
            ...point,
            city,
            start: startPrenotazione,
            end: endPrenotazione,
            percorso: [...ponintPercorso,...pointPosizioneIniziale],
          });
        }

        const raggruppati = raggruppaPerOrdineId(newPointsTmp);
        console.log(raggruppati, 'raggruppati');
        setPoints(raggruppati);
      };

      getCityNames();
    }
  }, [points]);

  const generaPercorso = (ponintPercorso) => {
    // da ponintPercorso elimino tutti i valori duplicati
    console.log(ponintPercorso,'pointPercorso');
    ponintPercorso = ponintPercorso.filter((point, index, self) => self.findIndex((t) => t[0] === point[0] && t[1] === point[1]) === index);

    let url = 'https://www.google.com/maps/dir/?api=1&destination=';
    let percorso = '';
    let counter = 0;
    for (const point of ponintPercorso) {
      if (counter === 0) {
        url += point[0] + ',' + point[1];
        counter++;
        continue;
      } else if (counter === 1) {
        url += '&waypoints=';
        counter++;
      }

      percorso += point[0] + ',' + point[1] + '|';
    }
    percorso = percorso.substring(0, percorso.length - 1);
    url += percorso;
    window.open(url, '_blank');
  };


  useEffect(() => {
    if(debouncedRaggruppaPerKm){
      let newRaggruppaPerKm ;
      if (!debouncedRaggruppaPerKm) return;

      if (debouncedRaggruppaPerKm){
       newRaggruppaPerKm = debouncedRaggruppaPerKm * 1000;
      }
      recalculate(null,newRaggruppaPerKm);
    }
  }, [debouncedRaggruppaPerKm]);
    // Debounce per "Raggruppa per km"
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedRaggruppaPerKm(raggruppaPerKm);
      }, 500); // 500ms debounce
  
      return () => {
        clearTimeout(handler);
      };
    }, [raggruppaPerKm]);



    const apriModal = (data) => {
      apriModalAssociaConsegna(data);
    }

    const updateConsegnaOrdine = async(stato,id) => {
      let updatetato = await updateStatoConsegna(stato,id);
      //recalculate(null,null,true);
      console.log(updatetato);
    }
    
    const changeStatoConsegna = (stato,ordineId) => {
      updateConsegnaOrdine(stato,ordineId);
      console.log(stato,ordineId);
      let newPointsTmp = [...newPoints];
      newPointsTmp.map((point, index) => {
        console.log(point);
        if (point.length){
          point.map((p) => {
            if(p.ordineId === ordineId){
              p.statoConsegna = stato;
            }
          })
        }
      })
      console.log(newPointsTmp);
      setPoints([...newPointsTmp]);
    }


  return (
    <>
  <div className="raggruppa-dati">
    {!paramValue 
      ?  <div className="raggruppa-dati-element">
          <label>Raggruppa per km:</label>
          <div className="table-container">
            <input
              type="number"
              min={1}
              placeholder="Raggruppa per km"
              onChange={(e) => setRaggruppaPerKm(e.target.value)}
            />
          </div>
          <div >
            <button className="btn-table" onClick={() => recalculate(null,null,true)}>Raggruppa per area</button>
          </div>
        </div>: null}
      </div> 


    <div className="table-container">
      <table className="responsive-table table-prenotazioni">
      <thead className="thead-prenotazioni">
            <tr>
              <th>
                <input
                  type="text"
                  style={{color:'black'}}
                  placeholder="ID ordine"
                  value={filters.ordineId}
                  onChange={(e) => handleFilterChange('ordineId', e.target.value)}
                />
              </th>
              <th>
                <input
                style={{color:'black'}}
                  type="text"
                  placeholder="Etichetta"
                  value={filters.etichetta}
                  onChange={(e) => handleFilterChange('info', e.target.value)}
                />
              </th>
              <th>
                <input
                style={{color:'black'}}
                  type="text"
                  placeholder="Cliente"
                  value={filters.cliente}
                  onChange={(e) => handleFilterChange('cliente', e.target.value)}
                />
              </th>
              <th>
                <input
                style={{color:'black'}}
                  type="text"
                  placeholder="Città"
                  value={filters.città}
                  onChange={(e) => handleFilterChange('city', e.target.value)}
                />
              </th>
              <th>
                <input
                style={{color:'black'}}
                  type="text"
                  placeholder="Area"
                  value={filters.area}
                  onChange={(e) => handleFilterChange('area', e.target.value)}
                />
              </th>
              <th>
                <input
                style={{color:'black'}}
                  type="text"
                  placeholder="Start"
                  value={filters.start}
                  onChange={(e) => handleFilterChange('start', e.target.value)}
                />
              </th>
              <th>
                <input
                style={{color:'black'}}
                  type="text"
                  placeholder="End"
                  value={filters.end}
                  onChange={(e) => handleFilterChange('end', e.target.value)}
                />
              </th>
              <th>
                <input
                style={{color:'black'}}
                  type="text"
                  placeholder="Pagamento"
                  value={filters.statoPagamento}
                  onChange={(e) => handleFilterChange('statoPagamento', e.target.value)}
                />
              </th>
              <th>
                <input
                style={{color:'black'}}
                  type="text"
                  placeholder="Stato"
                  value={filters.statoConsegna}
                  onChange={(e) => handleFilterChange('statoConsegna', e.target.value)}
                />
              </th>
              <th>Consegna/Ritiri</th>
              {(!paramValue && !isRitiri) ? <th>Annulla</th> : (paramValue || !paramValue) && isRitiri ? <th>ritiri</th> : null  }
              <th>Azioni</th>
            </tr>
          </thead>
        <tbody className='tbody-prenotazioni'>
          {filteredPoints.map((group, index) => (
            <>
              {/* Verifica se il gruppo è un delimitatore */}
              {group.isDelimiter ? (
                <tr key={index}>
                  <td colSpan="9"></td> {/* Riga vuota per il delimitatore */}
                </tr>
              ) : (
                <tr key={index}>
                  {/* Assicurati che group sia un array */}
                  <td  className='internal-row'>{Array.isArray(group) ? [...new Set(group.map((p) => p.ordineId))].map((e,i) =><tr>{e}</tr> ) : ''}</td>
                  <td data-label="etichetta" className='internal-row'>
                    {Array.isArray(group) ? (
                      [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                        const info = group
                          .filter((p) => p.ordineId === ordineId)
                          .map((p) => p.info)
                          .join(', ');

                        return (
                          <tr key={ordineId} className='internal-row'>
                            <td  className='internal-row'>{info}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    )}
                  </td>
                  <td  data-label="cliente" className='internal-row'>
                    {Array.isArray(group) ? (
                      [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                        let info = group
                          .filter((p) => p.ordineId === ordineId)
                          .map((p) => p.cliente)
                          .join(', ');
                        // rendo univoco il nome del cliente
                        info = [...new Set(info.split(', '))].join(', ');

                        return (
                          <tr key={ordineId} className='internal-row'>
                            <td  className='internal-row'>{info}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    )}
                  </td>
                  <td  data-label="città" className='internal-row'>
                    {Array.isArray(group) ? (
                      [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                        let info = group
                          .filter((p) => p.ordineId === ordineId)
                          .map((p) => p.city)
                          .join(', ');
                        // rendo univoco il nome del cliente
                        info = [...new Set(info.split(', '))].join(', ');
                        
                        return (
                          <tr key={ordineId} className='internal-row'>
                            <td  className='internal-row'>{info}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    )}
                  </td>
                  <td data-label="area" className='internal-row'>
                    {Array.isArray(group) ? (
                      [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                        let info = group
                          .filter((p) => p.ordineId === ordineId)
                          .map((p) => p.area)
                          .join(', ');
                        // rendo univoco il nome del cliente
                        info = [...new Set(info.split(', '))].join(', ');
                        
                        return (
                          <tr key={ordineId} className='internal-row'>
                            <td  className='internal-row'>{info}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    )}
                  </td>
                  <td data-label="inizio" className='internal-row'>
                    {Array.isArray(group) ? (
                      [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                        let info = group
                          .filter((p) => p.ordineId === ordineId)
                          .map((p) => p.start)
                          .join(', ');
                        // rendo univoco il nome del cliente
                        info = [...new Set(info.split(', '))].join(', ');
                        
                        return (
                          <tr key={ordineId} className='internal-row'>
                            <td  className='internal-row'>{info}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    )}
                  </td>
                  <td  data-label="fine" className='internal-row'>
                    {Array.isArray(group) ? (
                      [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                        let info = group
                          .filter((p) => p.ordineId === ordineId)
                          .map((p) => p.end)
                          .join(', ');
                        // rendo univoco il nome del cliente
                        info = [...new Set(info.split(', '))].join(', ');
                        
                        return (
                          <tr key={ordineId} className='internal-row'>
                            <td  className='internal-row'>{info}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    )}
                  </td>
                  <td data-label="pagmento" className='internal-row'>
                    {Array.isArray(group) ? (
                      [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                        let info = group
                          .filter((p) => p.ordineId === ordineId)
                          .map((p) => p.statoPagamento)
                          .join(', ');
                        // rendo univoco il nome del cliente
                        info = [...new Set(info.split(', '))].join(', ');
                        
                        return (
                          <tr key={ordineId} className='internal-row'>
                            <td  className='internal-row'>{info}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    )}
                  </td>
                  <td  data-label="stato" className='internal-row'>
                    {Array.isArray(group) ? (
                      [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                        let info = group
                          .filter((p) => p.ordineId === ordineId)
                          .map((p) => p.statoConsegna)
                          .join(', ');
                        // rendo univoco il nome del cliente
                        info = [...new Set(info.split(', '))].join(', ');
                        
                        return (
                          <tr key={ordineId} className='internal-row'>
                            <td className='internal-row'>
                              {info !== 'annullato' ?
                              <select 
                                className='custom-select-giri-consegne' 
                                value={newPoints[index]?.statoConsegna || info} 
                                onChange={(e) => changeStatoConsegna(e.target.value, ordineId)} 
                              >
                                <option value="Consegnato">Consegnato</option>
                                <option value="daconsegnare">Non consegnato</option>
                                {/*<option value="Ritirato">Ritirato</option>*/}
                                {/*<option value="daritirare">Non ritirato</option>*/}
                              </select> : info}
                            </td>
                            {/*<td onClick={() => console.log(ordineId)}>{info}</td>*/}
                          </tr>
                        );
                      })
                    ) : (
                      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    )}
                  </td>
                  <td className='internal-row'>
                    {Array.isArray(group) ? (
                      [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                        // Recupero delle consegne
                        let consegne = group
                          .filter((p) => p.ordineId === ordineId)
                          .map((p) => p.consegne);

                        // Recupero dei ritiri
                        let ritiri = group
                          .filter((p) => p.ordineId === ordineId)
                          .map((p) => p.ritiri);

                        // Funzione per eliminare duplicati
                        const eliminaDuplicatiArrayDiOggetti = (arrayDiArray) => {
                          const mergedArray = arrayDiArray.flat();
                          const uniqueArray = Array.from(
                            new Map(mergedArray.map(item => [item._id, item])).values()
                          );
                          return uniqueArray;
                        };

                        // Elimina duplicati per consegne e ritiri
                        consegne = eliminaDuplicatiArrayDiOggetti(consegne);
                        ritiri = eliminaDuplicatiArrayDiOggetti(ritiri);

                        // Renderizzazione delle righe per ordine
                        return (
                          <tr key={ordineId} className='internal-row'>
                            <td className='internal-row'>
                              <div>
                                {/* Mostra il nome della consegna */}
                                <strong>Consegna:</strong> {consegne[0]?.nome || 'N/A'}
                              </div>
                              <div>
                                {/* Mostra il nome del ritiro */}
                                <strong>Ritiro:</strong> {ritiri[0]?.nome || 'N/A'}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </td>
                  {(!paramValue && !isRitiri) && <td  className='internal-row'>{Array.isArray(group) ? [...new Set(group.map((p) => p.ordineId))].map((e,i) =><tr> <button className="btn-table" onClick={()=>apriModalAnnullaOrdine(group,e)}>annullaOrdine</button></tr> ) : ''}</td>}
                  {(isRitiri) && <td  className='internal-row'>{Array.isArray(group) ? [...new Set(group.map((p) => p.ordineId))].map((e,i) =><tr> <button className="btn-table" onClick={()=>apriModalRitiri(group,e)}>ritirato</button></tr> ) : ''}</td>}
                  <td  className='internal-row'>
                    {Array.isArray(group) && (
                      <>
                        <button
                          className="btn-table"
                          onClick={() => generaPercorso(group.flatMap((p) => p.percorso))}
                        >
                          Percorso
                        </button>
                        {!paramValue &&
                        <>
                        <button className="btn-table" onClick={()=>apriModal(group)}>Condividi</button>
                        </>
                        }
                      </>
                    )}
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default MostraGiro;


