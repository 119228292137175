// client/src/components/OnboardingButton.js
import React, { useState } from 'react';
import axios from 'axios';

const OnboardingButton = ({user}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      // Creazione dell'account Stripe Connect
      const createAccountResponse = await axios.post('http://localhost:5000/stripe/create-connect-account', {
        //email: 'pippo@example.com', // Sostituisci con l'email del driver
        ...user
      });

      const accountId = createAccountResponse.data.accountId; // Recupera l'accountId dal response
      console.log('Account ID:', accountId);

      // Creazione del link di onboarding per l'account creato
      const createLinkResponse = await axios.post('http://localhost:5000/stripe/create-account-link', { accountId });
      const url = createLinkResponse.data.url; // Recupera l'URL dal response

      // Reindirizza l'utente all'URL di onboarding
      window.location.href = url;
    } catch (error) {
      console.error("Errore durante la creazione dell'onboarding:", error);
      setLoading(false);
    }
  };

  return (
    <button className='button-generic' onClick={handleClick} disabled={loading}>
      {loading ? 'Caricamento...' : 'Inizia onboarding Stripe Connect'}
    </button>
  );
};

export default OnboardingButton;
