// client/src/pages/OnboardingRefresh.js
import React, { useEffect } from 'react';

const OnboardingRefresh = () => {
  const handleRetry = () => {
    window.location.reload(); // Ricarica la pagina per riprovare
  };

  useEffect(() => {
    console.log('Onboarding refresh necessario');
  }, []);

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>Onboarding non completato</h1>
      <p>Per favore, riprova a completare l'onboarding Stripe Connect.</p>
      <button onClick={handleRetry} style={{ marginTop: '20px' }}>
        Riprova
      </button>
    </div>
  );
};

export default OnboardingRefresh;
