import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {raggruppaPrenotazioniAree,changeKanbanPulizie,deleteKanbanPulizie}  from  '../../api/prodotti/api';
import './css/DropArea.css';
import Spinner from 'react-bootstrap/Spinner';

// Componente wrapper per il rendering ritardato dei Droppable
const DropWrapper = ({ droppableId, children, direction = "vertical" }) => {
  const [enabled, setEnabled] = useState(false);



  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) return null;



  return (
    <Droppable droppableId={droppableId} direction={direction}>
      {children}
    </Droppable>
  );
};

const colors = {0:'violet', 1: 'darkKhaki', 2: 'blue', 3: 'green', 4: 'yellow', 5: 'purple', 6: 'orange', 7: 'pink', 8: 'brown', 9: 'black', 10: 'grey'};
const App = () => {
  const [columns, setColumns] = useState();

  const [cardPool, setCardPool] = useState([

  ]);
  const [loadersOn, setLoadersOn] = useState(false);
  const populateColumns = (inKamban) => {
    const newColumns = {
      lunedi: {
        id: "lunedi",
        title: "Lunedì",
        cards: [
  
        ],
      },
      martedi: {
        id: "martedi",
        title: "Martedì",
        cards: [],
      },
      mercoledi: {
        id: "mercoledi",
        title: "Mercoledì",
        cards: [],
      },
      giovedi: {
        id: "giovedi",
        title: "Giovedì",
        cards: [],
      },
      venerdi: { id: "venerdi", title: "Venerdì", cards: [] },
      sabato: { id: "sabato", title: "Sabato", cards: [] },
      domenica: { id: "domenica", title: "Domenica", cards: [] },
    };
  
    // Aggiungi il totale dei bagni per ogni elemento
    const dataConTotaleBagni = inKamban.map((element) => {
      const numeroBagniTotali = element.ordini.reduce((totale, ordine) => totale + ordine.numeroBagni, 0);
      return {
        ...element,
        numeroBagniTotali, // Aggiungi la somma al nuovo oggetto
      };
    });
  
    // Crea la struttura per il caricamento dei dati nelle colonne
    const cadTmp = [];
    dataConTotaleBagni.forEach((element) => {
      cadTmp.push({
        [element.giorno]: {
          colors: element.numeroBagniTotali > 10 ? colors[10] : colors[element.numeroBagniTotali],
          nBagni: `🚽 ${element.numeroBagniTotali}`,
          id: `${element.area}${element.organizzazionePulizie}`,
          content: `📍 ${element.area}`,
          time: `📅 ${element.organizzazionePulizie}`,
        },
      });
    });
  
    // Aggiungi i dati alle colonne


    cadTmp.forEach((element) => {
      const key = Object.keys(element)[0];
  
      // Controlla se la colonna esiste e se contiene l'array `cards`
      if (newColumns[key]) {
        if (!newColumns[key].cards) {
          newColumns[key].cards = []; // Inizializza l'array se non esiste
        }
        newColumns[key].cards.push(element[key]);  // Aggiungi l'elemento alla lista di cards
      }
    });

    // creo una primise e aspetto un secondo per risolverla


    setColumns(newColumns); // Aggiorna lo stato delle colonne
  };
  



  const getDataForCard = async () => {
    setLoadersOn(true);
    if(cardPool.length)  return;
    let data = await raggruppaPrenotazioniAree()
    console.log(data)
    if(!data?.outKanban?.length && !data?.inKanban?.length  || data.message =='Nessuna area trovata') {
      setLoadersOn(false);
      return;
    } 
    
    let dataInKanban = data?.inKanban
    data = data?.outKanban.length? [...data?.outKanban] :[]

    
    /**
     *     {
        "area": "area2",
        "organizzazionePulizie": "1m",
        "ordini": [
            {
                "ordineId": "6740c14310b89e189fbca0b7",
                "numeroBagni": 4,
                "puliziaIsActived": true,
                "puliziaRicorrenza": "1m",
                "nomePrenotazione": "ale ancona"
            }
        ]
    },
     * 
     */
    const dataConTotaleBagni = data.map((element) => {
      const numeroBagniTotali = element.ordini.reduce((totale, ordine) => totale + ordine.numeroBagni, 0);
      return {
        ...element,
        numeroBagniTotali, // Aggiungi la somma al nuovo oggetto
      };
    });
    
    // Creazione di cadTmp
    const cadTmp = [];
    dataConTotaleBagni.forEach((element) => {
      cadTmp.push({
        colors: element.numeroBagniTotali > 10?colors[10] : colors[element.numeroBagniTotali],
        nBagni: `🚽 ${element.numeroBagniTotali}`,
        id: `${element.area}${element.organizzazionePulizie}`,
        content: `📍 ${element.area}`,
        time:`📅 ${element.organizzazionePulizie}`
      });
    });
    
    // Esempio di aggiornamento dello stato
    setCardPool([...cadTmp]);
    populateColumns(dataInKanban)
    setLoadersOn(false);
  }
  useEffect(() => {
    if (!cardPool.length && !columns) {
      getDataForCard()
    }
  }, [cardPool]);



  const handleDragEnd = async (result) => {
    setLoadersOn(true)
    const { source, destination } = result;
  
    // Se non c'è destinazione (rilasciato fuori), esci
    if (!destination) return;
  
    // Se rilasciato nello stesso posto
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
  
    if (source.droppableId === "cardPool" && destination.droppableId === "cardPool") {
      // Caso: spostamento all'interno del card pool
      const reorderedPool = Array.from(cardPool);
      const [movedCard] = reorderedPool.splice(source.index, 1);
      reorderedPool.splice(destination.index, 0, movedCard);
  
      setCardPool(reorderedPool);
    } else if (source.droppableId === destination.droppableId) {
      // Caso: spostamento all'interno della stessa colonna
      const column = columns[source.droppableId];
      const reorderedCards = Array.from(column.cards);
      const [movedCard] = reorderedCards.splice(source.index, 1);
      reorderedCards.splice(destination.index, 0, movedCard);
  
      setColumns((prev) => ({
        ...prev,
        [source.droppableId]: {
          ...prev[source.droppableId],
          cards: reorderedCards,
        },
      }));
    } else if (destination.droppableId === "cardPool") {
      // Caso: sposta una card da una colonna al pool
      const sourceCards = [...columns[source.droppableId].cards];
      const [movedCard] = sourceCards.splice(source.index, 1);

      let {time, content} = movedCard
      content = content.replace('📍', '').trim()
      time = time.replace('📅', '').trim()



      await deleteKanbanPulizie(time, content)  
  
      setColumns((prev) => ({
        ...prev,
        [source.droppableId]: {
          ...prev[source.droppableId],
          cards: sourceCards,
        },
      }));
  
      setCardPool((prev) => [...prev, movedCard]);
    } else if (source.droppableId === "cardPool") {
      // Caso: sposta dal pool a una colonna
      const cardToMove = cardPool[source.index];
      let {time, content} = cardToMove
      content = content.replace('📍', '').trim()
      time = time.replace('📅', '').trim()
      const destinationColunm = columns[destination.droppableId]
      console.log('spostamento dal pool a una colonna', cardToMove)
      console.log('colonna destinazione', columns[destination.droppableId].id)

      await changeKanbanPulizie(time, content, destinationColunm.id)
      setCardPool((prev) => prev.filter((_, idx) => idx !== source.index));
  
      setColumns((prev) => ({
        ...prev,
        [destination.droppableId]: {
          ...prev[destination.droppableId],
          cards: [
            ...prev[destination.droppableId].cards.slice(0, destination.index),
            cardToMove,
            ...prev[destination.droppableId].cards.slice(destination.index),
          ],
        },
      }));
    } else {
      console.log('spostamento tra colonne')
      // Caso: sposta tra colonne diverse
      const sourceCards = [...columns[source.droppableId].cards];
      const [movedCard] = sourceCards.splice(source.index, 1);
  
      const destinationCards = [...columns[destination.droppableId].cards];
      destinationCards.splice(destination.index, 0, movedCard);
      console.log(columns[source.droppableId], columns[destination.droppableId])
      const destinationColunm = columns[destination.droppableId]
      let {time, content} = movedCard
      content = content.replace('📍', '').trim()
      time = time.replace('📅', '').trim()


      // chiamo le api e aggiorno le pulizie passando time, content e destinationColunm.id
      await changeKanbanPulizie(time, content, destinationColunm.id)
  
      setColumns((prev) => ({
        ...prev,
        [source.droppableId]: {
          ...prev[source.droppableId],
          cards: sourceCards,
        },
        [destination.droppableId]: {
          ...prev[destination.droppableId],
          cards: destinationCards,
        },
      }));
    }
    getDataForCard()
    setLoadersOn(false)
  };
  
  
  

  return (
    <div className="App-drag">
      <h1>Drag-and-Drop Weekly Table</h1>
      {loadersOn ? 
      <div className="loader-container">
        <div>
      <h2>Caricamento in corso...</h2>
      </div>
      <div>
      <Spinner animation="grow" variant="primary" />
      </div>
      </div> :
      <DragDropContext onDragEnd={handleDragEnd}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {columns && Object.entries(columns).map(([key, column]) => (
            <DropWrapper key={key} droppableId={key}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    padding: "10px",
                    width: "150px",
                    minHeight: "200px",
                    background: "#f9f9f9",
                  }}
                >
                  <h3>{column.title}</h3>
                  {column.cards.map((card, index) => (
                    <Draggable key={card.id} draggableId={card.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            padding: "10px",
                            margin: "0 0 10px 0",
                            background: "#fff",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            ...provided.draggableProps.style,
                            backgroundColor: card.colors
                          }}
                        >
                        <div className="container-info">
                        <span>{card.content}</span>
                        <span>{card.nBagni}</span>
                        <span>{card.time}</span>
                        </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </DropWrapper>
          ))}
        </div>
        <div style={{ marginTop: "20px", display:'flex',flexWrap:'wrap' }}>
          <h2>Card Pool</h2>
          <DropWrapper droppableId="cardPool" direction="horizontal">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "flex",
                  gap: "10px",
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  background: "#f3f3f3",
                  
                }}
              >
                {cardPool.map((card, index) => (
                  <Draggable key={card.id} draggableId={card.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          padding: "10px",
                          background: "#fff",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          ...provided.draggableProps.style,
                          backgroundColor: card.colors
                        }}
                      >
                        <div className="container-info">
                        <span>{card.content}</span>
                        <span>{card.nBagni}</span>
                        <span>{card.time}</span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </DropWrapper>
        </div>
      </DragDropContext>
      }
    </div>
  );
};

export default App;
