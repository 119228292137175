import React from "react";
import "./css/CardServizi.css"; // Assicurati di creare questo file per lo stile

const modules = [
  { name: "Giri Pulizie", icon: "🧹", description: "Organizza i giri di pulizia e monitora lo stato dei bagni." },
  { name: "Consegne", icon: "🚚", description: "Gestisci le consegne dei bagni chimici ai clienti." },
  { name: "Aree", icon: "📍", description: "Crea e assegna aree geografiche per i servizi." },
  { name: "Clienti", icon: "👤", description: "Offri un area dedicata per visualizzare lo stato del servizio." },
  { name: "Collaboratori", icon: "🛠️", description: "Gestisci  e pianifica i compiti dei tui collaboratori." },
  { name: "Report", icon: "📊", description: "Analizza i dati di utilizzo e manutenzione." },
  { name: "Magazzino", icon: "🏗️", description: "Monitora la disponibilità e lo stato dei bagni." },
  { name: "Assistenza", icon: "⚙️", description: "Risolvi problemi e gestisci richieste di supporto." },
];

const CardServizi = () => {
  return (
    <div className="dashboard-container">
      <h1>Moduli e Funzionalità</h1>
      <div className="modules-grid">
        {modules.map((module, index) => (
          <div key={index} className="module-card">
            <div className="module-icon">{module.icon}</div>
            <h2 className="module-h2">{module.name}</h2>
            <p className="module-p">{module.description}</p>
            {/*<button className="module-ntn">Scopri di più</button>*/}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardServizi;
