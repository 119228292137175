


import  { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignatureForm from "./SignatureForm";

import './css/Dashboard.css';

const Dashboard = () => {
    const [goToDashboard, setGoToDashboard] = useState(false)

    const navigate = useNavigate();
    const handleGoToDashboard = (goTo) => {

        if(goTo){
            navigate(goTo)
        }
        
    }
    return (
        <div className='dashboard'>
            <SignatureForm />
        </div>
    );
    }

export default Dashboard;