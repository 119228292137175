import { useState, useEffect } from 'react';
import { 
  giveMeBagniPremootatiForDayAndUser,
  giveMeAllCollaboratoreAssociatoConsegneRitiri,
  annullaOrdineFromId,
  giveMeBagniRitiriForDayAndUser,
  creaRitiro
} from '../../api/prodotti/api';
import MostraTutti from '../map/MostraTutti';
import MostraTabella from './tabelle/MostraPrenotazioni'; // Correzione del nome dell'import
import ModalAssociaConsegna from './modali/ModalAssociaConsene';
import ModalAnnullaOrdine from './modali/ModalAnnullaOrdine';
import ModalRitiri from './modali/ModalRitiro';
import  {associaCollaboratoreConsegne} from '../../api/collaboratori/api';
import Calendario from './Calendario'; 
import table from '../../image/table.png'; // Importa l'immagine per il bottone
import map from '../../image/map.png';
import './css/Prenotazioni.css';
import { set } from 'lodash';

const Prenotazioni = ({ option,startDate,endDate }) => {
  const [data, setData] = useState([]);
  const [dataRitiri, setDataRitiri] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [mostraMappa, setMostraMappa] = useState(false);
  const [mostraTabella, setMostraTabella] = useState(false);
  /*const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  */
  const [modalAssociaConsegna, setModalAssociaConsegna] = useState(false);
  const [datiAssociazione, setDatiAssociazione] = useState();


  const [modalAnnullaOrdine, setModalAnnullaOrdine] = useState(false);
  const [dataAnnullaOrdine, setDataAnnullaOrdine] = useState();

  const [isRitiri, setIsRitiri] = useState(false);


  const [modalRitiri, setModalRitiri] = useState(false);
  const [daRitirare, setDaRitirare] = useState([]);




 

  
  const getAllBagniUtente = async (time,distanza,area) => {
    if (option == 'bagni'){
        try {
          console.log(isRitiri,'isRitiri');
          const response =  await giveMeBagniPremootatiForDayAndUser(startDate, endDate,time,distanza,area);
          const responseRitiri = await giveMeBagniRitiriForDayAndUser();
        console.log(response,'response');

        const collaboratori = await getAllCollaboratori();
        //console.log(response);
        let dataTmap = [];
        let dataTmapRitiri = [];
        response.forEach((element) => {
           
            let ritiri = collaboratori.ritiri.filter(collaboratore => collaboratore.idOrdine == element?.ordineId?._id);
            let consegne = collaboratori.consegne.filter(collaboratore => collaboratore.idOrdine ==element?.ordineId?._id);
            // elimino gli elementi duplicati dall'array di oggetti ritiri e consegne
            ritiri = ritiri.filter((collaboratore, index, self) =>
            index === self.findIndex((t) => (
                t.idOrdine === collaboratore.idOrdine
            ))
            );
            consegne = consegne.filter((collaboratore, index, self) =>
            index === self.findIndex((t) => (
                t.idOrdine === collaboratore.idOrdine
            ))
            );

            console.log(ritiri,'ritiri-filter');
            console.log(consegne,'filter');
            let empty = {_id:'',nome:'Non assegnato'};
            dataTmap.push({
            lat: element.posizione?.coordinates[1],
            lng: element.posizione?.coordinates[0],
            info: element.bagnoId?.numero,
            start: element.inizioPrenotazione ? element.inizioPrenotazione : '',
            end: element.finePrenotazione ? element.finePrenotazione : '',
            statoPagamento: element.statoPagamento ? element.statoPagamento[0] :'',
            statoConsegna: element.stato ? element.stato[0] :'',
            cliente: element.nomePrenotazione,
            ordineId: element.ordineId?._id || '',
            posizione: element.bagnoId?.posizione?.coordinates,
            collaboratoreId: element.collaboratoreId?.length ? element.collaboratoreId : [empty],
            consegne: consegne.length ? {nome:consegne[0]?.idCollaboratore?.nome, id:consegne[0]._id} : {nome:empty.nome,id:empty._id},
            ritiri: ritiri.length ? {nome:ritiri[0]?.idCollaboratore?.nome, id:ritiri[0]._id} : {nome:empty.nome,id:empty._id},
            area:element.ordineId?.area,
            });
        });
        responseRitiri.forEach((element) => {
            let ritiri = collaboratori.ritiri.filter(collaboratore => collaboratore.idOrdine == element?.ordineId?._id);
            let consegne = collaboratori.consegne.filter(collaboratore => collaboratore.idOrdine ==element?.ordineId?._id);
            // elimino gli elementi duplicati dall'array di oggetti ritiri e consegne
            ritiri = ritiri.filter((collaboratore, index, self) =>
            index === self.findIndex((t) => (

                t.idOrdine === collaboratore.idOrdine
            ))
            );
            consegne = consegne.filter((collaboratore, index, self) =>
            index === self.findIndex((t) => (
                t.idOrdine === collaboratore.idOrdine
            ))
            );
            let empty = {_id:'',nome:'Non assegnato'};
            dataTmapRitiri.push({
            lat: element.posizione?.coordinates[1],
            lng: element.posizione?.coordinates[0],
            info: element.bagnoId?.numero,
            start: element.inizioPrenotazione ? element.inizioPrenotazione : '',
            end: element.finePrenotazione ? element.finePrenotazione : '',
            statoPagamento: element.statoPagamento ? element.statoPagamento[0] :'',
            statoConsegna: element.stato ? element.stato[0] :'',
            cliente: element.nomePrenotazione,
            ordineId: element.ordineId?._id || '',
            posizione: element.bagnoId?.posizione?.coordinates,
            collaboratoreId: element.collaboratoreId?.length ? element.collaboratoreId : [empty],
            consegne: consegne.length ? {nome:consegne[0]?.idCollaboratore?.nome, id:consegne[0]._id} : {nome:empty.nome,id:empty._id},
            ritiri: ritiri.length ? {nome:ritiri[0]?.idCollaboratore?.nome, id:ritiri[0]._id} : {nome:empty.nome,id:empty._id},
            area:element.ordineId?.area,
            });
        });



          setData(dataTmap);
          

          setDataRitiri(dataTmapRitiri);
        
        
        setLoading(false);
        setMostraTabella(true);
        } catch (error) {
        setError(true);
        setErrorText(error.message);
        setLoading(false);
        }
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      getAllBagniUtente();
    }
  }, [option, startDate, endDate]);

/*
  const getData = (startDate,endDate) => {
    if (startDate) {
        setStartDate(startDate)
    }
    if (endDate) {
        setEndDate(endDate)
    }
}*/

const getAllCollaboratori = async () => {
  try {
    const response = await giveMeAllCollaboratoreAssociatoConsegneRitiri();
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
  
}

const giveMeRitiri = async () => {
  try {
    const response = await giveMeBagniRitiriForDayAndUser();
    setDataRitiri(response);
  } catch (error) {
    console.log(error);
  }
}
const recalculate = (time,distanza,area) => {
  getAllBagniUtente(time,distanza,area);
}

const ChiudiModalAssociaConsegna = () => {
  setModalAssociaConsegna(false);
};

const inviaDatiAssociazione = async(data,collaborateroId) => {
  console.log(data,collaborateroId);
  const {consegna,ritiro} = data;
  const uniqOrdiniId = [...new Set(datiAssociazione.map(item => item.ordineId))];
  // per ogni uniqOrdiniId effetuo la chiamata a associaCollaboratoreConsegne

  for (let i = 0; i < uniqOrdiniId.length; i++) {
    const ordineId = uniqOrdiniId[i];
    // estraggo tutti 
    try {
      const response = await associaCollaboratoreConsegne(collaborateroId,ordineId,consegna,ritiro);
      console.log(response);
      //recalculate();
    } catch (error) {
      console.log(error);
    }
  }
  setModalAssociaConsegna(false);
}

const apriModalAssociaConsegna = (data) => {
  setDatiAssociazione(data);
  setModalAssociaConsegna(true);
}

const apriModalAnnullaOrdine = (data,e) => {
  console.log(data,e);
  let newdata = data.filter(item => item.ordineId == e);
  setDataAnnullaOrdine(newdata);
  setModalAnnullaOrdine(true);
}

const apriModalRitiri = (data,e) => {
  console.log(data,e);
  let newdata = data.filter(item => item.ordineId == e);
  setDaRitirare(newdata);
  setModalRitiri(true);
}

const annulaOrdine = async () => {
  const ordineId = dataAnnullaOrdine.map(item => item.ordineId);
  // ripulisco l'array da eventuali duplicati
  const uniqOrdiniId = [...new Set(ordineId)];
  console.log(uniqOrdiniId);
  const resp = await annullaOrdineFromId(uniqOrdiniId);
  getAllBagniUtente(null,null,true);
  console.log(resp);
  setModalAnnullaOrdine(false);
}

const ritiraOrdine = async(data) => {
  const ordineId = daRitirare.map(item => item.ordineId);
  // ripulisco l'array da eventuali duplicati
  const uniqOrdiniId = [...new Set(ordineId)];
  console.log(uniqOrdiniId,'ritiri');
  //
  let resp = await creaRitiro(uniqOrdiniId,data);
  setModalRitiri(false);
  // in data c'è l'id del magazzino
  getAllBagniUtente(null,null,true);
  console.log(resp)
}
  return (
    <div className='prenotazioni'>
      <ModalAssociaConsegna isOpen={modalAssociaConsegna} onClose={ChiudiModalAssociaConsegna} onSubmit={inviaDatiAssociazione} />
      <ModalAnnullaOrdine isOpen={modalAnnullaOrdine} onClose={() => setModalAnnullaOrdine(false)} onSubmit={annulaOrdine} />
      < ModalRitiri  isOpen={modalRitiri} onClose={() => setModalRitiri(false)} onSubmit={ritiraOrdine}/>
      {/*<Calendario getData={getData}/>*/}
      {loading && <p>Caricamento...</p>}
      {error && <p>Si è verificato un errore: {errorText}</p>}
      {!loading && !error && (
        <div>
          {data.length === 0 && <p>Nessun bagno trovato</p>}
        
            <div>
              <div className='cont-button-bagni-prenotazioni'>
                {/* Corretto l'uso delle funzioni di stato per mostrare mappa e tabella */}
                <img src={map} title="mostra mappa" className='pointer-img' alt='mappa'onClick={() => {
                    setMostraMappa(true);
                    if (mostraTabella) {
                      setMostraTabella(false);
                    }
                  }} />
                <img src={table} title="mostra tabella" className='pointer-img' alt='tabella' onClick={() => {
                    setMostraTabella(true);
                    if (mostraMappa) {
                      setMostraMappa(false);
                    }
                  }} />
                  {/* creo uno switch*/}
                <label className="switch">
                  <input type="checkbox" onClick={() => setIsRitiri(!isRitiri)} />
                  <span className="slider round"></span>
                </label>
                <p>{isRitiri ? 'Ritiri' : 'Consegne'}</p>
              
              
              
                {/*<button
                  onClick={() => {
                    setMostraMappa(!mostraMappa);
                    if (mostraTabella) {
                      setMostraTabella(false);
                    }
                  }}
                >
                  Mostra sulla mappa
                </button>
                <button
                  onClick={() => {
                    setMostraTabella(!mostraTabella);
                    if (mostraMappa) {
                      setMostraMappa(false);
                    }
                  }}
                >
                  Mostra tabella
                </button>*/}
              </div>
              {mostraMappa && <MostraTutti points={data} />}
              {mostraTabella && 
              <MostraTabella points={isRitiri ? dataRitiri:  data}
               isRitiri={isRitiri}
               apriModalAssociaConsegna={apriModalAssociaConsegna} 
               modalConsegneIsOpen={modalAssociaConsegna}
               modalRitiriIsOpen={modalRitiri}
               apriModalRitiri={apriModalRitiri} 
               recalculate={recalculate}
               apriModalAnnullaOrdine={apriModalAnnullaOrdine}
               />}
            </div>
    
        </div>
      )}
    </div>
  );
};

export default Prenotazioni;
