/* creo funzioni per le api di user */
import { getApi } from '../api';
export const createUser = async (user) => {
    const response = await fetch(`${getApi()}user/createUser`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
    });
    const data = await response.json();
    return data;
}

export const loginUser = async (user) => {
    const response = await fetch(`${getApi()}user/loginUser`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
    });
    console.log(response);
    const data = await response.json();
    return data;
}

export const getUsers = async () => {
    const response = await fetch(`${getApi()}user/getUsers`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    });
    const data = await response.json();
    return data;
}

export const getUser = async () => {
    const response = await fetch(`${getApi()}user/getUser`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    });
    const data = await response.json();
    return data;
}


export const deleteUser = async (id) => {
    const response = await fetch(`${getApi()}user/deleteUser/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization ': `Bearer ${localStorage.getItem('token')}`
        },
    });
    const data = await response.json();
    return data;
}

export const updateUser = async (id, user) => {
    const response = await fetch(`${getApi()}user/updateUser/${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(user),
    });
    const data = await response.json();
    return data;
}