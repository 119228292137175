

import './css/Footer.css'


const Footer = () => {

    return (
    <footer class="footer">
        <div class="footer-container">
            
            <div class="footer-section">
                <h2>BagniPro</h2>
                <p>Soluzioni pratiche e affidabili per la gestione di eventi e cantieri.Per garantire la massima igiene e sicurezza.</p>
            </div>

        
            <div class="footer-section">
                <h3>Link Utili</h3>
                <ul class="footer-links">
                    <li><a href="#">Home</a></li>
                    <li><a href="#">Servizi</a></li>
                    <li><a href="#">Chi Siamo</a></li>
                    <li><a href="#">Contattaci</a></li>
                    <li><a href="#">Preventivi</a></li>
                </ul>
            </div>

            <div class="footer-section">
                <h3>Contatti</h3>
                <p>Email: <a href="mailto:info@affittobagnichimici.it">info@affittobagnichimici.it</a></p>
                <p>Telefono: <a href="tel:+390123456789">+39 012 345 6789</a></p>
                <p>Indirizzo: Via Roma 123, Milano, Italia</p>
            </div>

            <div class="footer-section">
                <h3>Seguici</h3>
                <div class="social-icons">
                    <a href="#"><img src="icon-facebook.svg" alt="Facebook"/></a>
                    <a href="#"><img src="icon-instagram.svg" alt="Instagram"/></a>
                    <a href="#"><img src="icon-twitter.svg" alt="Twitter"/></a>
                    <a href="#"><img src="icon-linkedin.svg" alt="LinkedIn"/></a>
                </div>
            </div>
        </div>


        <div class="footer-bottom">
            <p>&copy; 2024 Gestore Bagni Pro. Tutti i diritti riservati.</p>
            <ul>
                <li><a href="#">Termini e Condizioni</a></li>
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Cookie Policy</a></li>
            </ul>
        </div>
    </footer>

    );
};

export default Footer;
